import Collapse from '@kunukn/react-collapse'
import { observer } from 'mobx-react-lite'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from '../../../theme'
import { Button, Divider, Space, SVGIcon, SVGNames, Typography } from '../../atoms'

interface AccordionProps {
  isOpen: boolean
  title: string
  index?: number
  description: string
  children?: ReactElement | ReactNode
  noteUnderTitle?: string
  onClose?(index?: number): void

  onOpen?(index?: number): void

  showContinueBtn?: boolean
}

export const Accordion: React.FC<AccordionProps> = observer(
  ({
    isOpen,
    title,
    description,
    noteUnderTitle,
    onClose,
    children,
    showContinueBtn,
    index,
    onOpen
  }) => {
    const [expand, setExpand] = useState(isOpen)

    useEffect(() => {
      setExpand(isOpen)
    }, [index, isOpen])

    const handleClose = () => {
      setExpand(false)
      if (onClose && (index !== null || index !== undefined)) {
        onClose(index)
      }
    }

    const handleOpen = () => {
      setExpand(!expand)
      if (onOpen && (index !== null || index !== undefined)) {
        onOpen(index)
      }
    }

    return (
      <Space marginBottom={`${AppTheme.spacingSize.L}px`} height='auto'>
        <AccordionContainer className='accordion'>
          <TitleContainer onClick={handleOpen}>
            <div>
              <Typography
                fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
                color={AppTheme.colors.darkgray}>
                {title}
              </Typography>
              {!!noteUnderTitle && (
                <Typography
                  position='absolute'
                  fontSize={`${AppTheme.fonts.sizes.size_Paragraph}px`}
                  color={AppTheme.colors.primary}>
                  {noteUnderTitle}
                </Typography>
              )}
            </div>
            <SVGIcon name={SVGNames.IconEdit} active={!!noteUnderTitle && noteUnderTitle !== ''} />
          </TitleContainer>
          <Space mt={AppTheme.spacingSize.S} height='auto'>
            <Divider />
          </Space>
          <Collapse isOpen={expand} overflowOnExpanded={true}>
            <CollapsibleContainer className='collapsible_content'>
              <Space pt={`${AppTheme.spacingSize.M}px`} pb={`${AppTheme.spacingSize.XL}px`}>
                <Typography
                  fontSize={`${AppTheme.fonts.sizes.size_Form}px`}
                  lineHeight={`${AppTheme.fonts.lineHeights.lineHeight_Form}px`}
                  color={AppTheme.colors.darkgray}>
                  {description}
                </Typography>
                <Space mt={`${AppTheme.spacingSize.XL}px`}>
                  {children}
                  {/* <Select
                  isMultiple={false}
                  selectedOptions={selectedIcon}
                  setSelectedOptions={setSelectedIcon}
                /> */}
                </Space>
                {!!showContinueBtn && (
                  <Button hasBorder={false} onClick={handleClose}>
                    {'weiter'}
                  </Button>
                )}
              </Space>
            </CollapsibleContainer>
          </Collapse>
        </AccordionContainer>
      </Space>
    )
  }
)

Accordion.defaultProps = {
  showContinueBtn: true
}

const AccordionContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  position: relative;
`

interface CollapseProps {
  expanded?: boolean
  height?: number
}

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const CollapsibleContainer = styled.div<CollapseProps>`
  width: 100%;
  height: auto;
  /* max-height: ${(props) => (props.expanded ? '500px' : '0px')};
  transition: all 0.4s ease; */
  overflow: visible;
`
export default Accordion
