import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0px;
    font-family: ${(props) => props.theme.fonts.family};
    overflow: hidden;
  }

  *, *:before, *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  #root {
    height: 100%;
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
  }

  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .react-confirm-alert-overlay {
    z-index:10000!important;
  }

  .react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }

  .wc-app-enter {
  opacity: 0;
  }
  .wc-app-enter-active {
    opacity: 1;
    transition: opacity 500ms;
  }
`
