import React, { createContext, ReactElement, ReactNode, useState } from 'react'
import { AppTheme } from '../../theme'

export enum DeviceType {
  MOBILE = 'MOBILE',
  TABLET = 'TABLET',
  LAPTOP = 'LAPTOP'
}

type deviceTypes = DeviceType.MOBILE | DeviceType.TABLET | DeviceType.LAPTOP

type DeviceContextType = {
  device: deviceTypes
  // useLargeFont: boolean
  setDevice: (device: deviceTypes) => void
}
export const DeviceContext = createContext<DeviceContextType>({} as DeviceContextType)

// create ContextProvider
interface DeviceProviderProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}
export const DeviceContextProvider: React.FC<DeviceProviderProps> = ({ children }) => {
  const checkSize = () => {
    if (window.innerWidth < 968) {
      return DeviceType.MOBILE
    } else {
      return DeviceType.LAPTOP
    }
  }

  const handleResize = () => {
    setDevice(checkSize())
    AppTheme.window.innerHeight = window.innerHeight
    AppTheme.window.innerWidth = window.innerWidth
  }

  React.useEffect(() => {
    window.addEventListener('resize', () => handleResize())
    return () => {
      window.removeEventListener('resize', () => handleResize())
    }
  }, [])

  const [device, setDevice] = useState(checkSize())

  return <DeviceContext.Provider value={{ device, setDevice }}>{children}</DeviceContext.Provider>
}

export default DeviceContext
