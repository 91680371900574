export const standardQuestionsConfig = [
  {
    id: 'fdghdfghhteh5',
    title: 'Gebäudeart',
    question: 'Wählen Sie das passende Gebäude oder die Wohnung aus.',
    name: 'BUILDING'
  },
  {
    title: 'Baujahr',
    question: 'Wann wurde Ihre Wohnung /Ihr Haus gebaut?',
    name: 'YEAR',
    id: 'u8x9g3bdxaq'
  },
  {
    title: 'Fläche',
    question: 'Wie groß ist Ihre Wohnung /Ihr Haus?',
    name: 'AREA',
    id: 'c3v3bdxaq'
  },
  {
    title: 'Heizart',
    id: 'd0d3bdxaq',
    question: 'Womit heizen Sie aktuell?',
    name: 'HEATING_TYPE',
    additional: {
      id: 'zu6dfghhteh5',
      question: 'Gasanschluß vorhanden?',
      name: 'GAS_CONNECTION'
    }
  }
]

export const standardContentsLeftSide = [
  {
    config: {
      head: 'Gebäudeart',
      mandatory: true // e.g. used by main-content to determine if closable without choosing
    },
    elements: [
      {
        id: 'fdghdfghhteh5',
        choiceIsRelevantToPriceView: 'housing',
        field_x_fill_by_function: '',
        calculation: {
          type: 'calcServerSide',
          subType: 'oneValueId',
          topic: 'BUILDING'
        },
        order: 1,
        type: 'multi',
        questionLong: 'Wählen Sie das passende Gebäude oder die Wohnung aus.',
        title: '',
        text: 'text',
        question: 'Gebäudeart',
        selectSubType: 'single',
        min: 1,
        presentation: 'icon',
        options: [
          {
            field_1_value: 'Einfamilienhaus',
            field_1_value_id: 'EFH',
            field_1_icon: `
           <svg width="57px" height="45px" viewBox="0 0 57 45" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
             <desc>Created with Lunacy</desc>
             <g id="Icon-EFH" transform="translate(0.5 0.5)">
               <path d="M0 17L11.786 0L43.214 0L55 17" transform="translate(0.5 0.5)" id="Stroke-1" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
               <path d="M47 27L0 27L0 0L47 0L47 27ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11Z" transform="translate(4.5 16.5)" id="Stroke-3" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
             </g>
           </svg>
           `,
            field_1_is_svg: true,
            field_1_icon_on: `
            <svg width="57px" height="45px" viewBox="0 0 57 45" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <g id="Icon-EFH-Copy" transform="translate(0.5 0.5)">
                <path d="M0 17L11.786 0L43.214 0L55 17" transform="translate(0.5 0.5)" id="Stroke-1" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47 27L0 27L0 0L47 0L47 27ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11Z" transform="translate(4.5 16.5)" id="Stroke-3" fill="#primary" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
            `,
            field_1_default: true
          },
          {
            field_2_value: 'Mehrfamilienhaus',
            field_2_value_id: 'MFH',
            field_2_icon: `
            <svg width="57px" height="61px" viewBox="0 0 57 61" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <g id="Icon-MFH" transform="translate(0.5 0.5)">
                <path d="M0 17L11.786 0L43.214 0L55 17" transform="translate(0.5 0.5)" id="Stroke-1" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47 43L0 43L0 0L47 0L47 43ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11ZM19 23L8 23L8 16L19 16L19 23ZM28 23L39 23L39 16L28 16L28 23ZM8 35L19 35L19 28L8 28L8 35ZM39 35L28 35L28 28L39 28L39 35Z" transform="translate(4.5 16.5)" id="Stroke-3" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
            `,
            field_2_icon_on: `
            <svg width="57px" height="61px" viewBox="0 0 57 61" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <g id="Icon-MFH-Copy" transform="translate(0.5 0.5)">
                <path d="M0 17L11.786 0L43.214 0L55 17" transform="translate(0.5 0.5)" id="Stroke-1" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47 43L0 43L0 0L47 0L47 43ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11ZM19 23L8 23L8 16L19 16L19 23ZM28 23L39 23L39 16L28 16L28 23ZM8 35L19 35L19 28L8 28L8 35ZM39 35L28 35L28 28L39 28L39 35Z" transform="translate(4.5 16.5)" id="Stroke-3" fill="#primary" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
            `,
            field_2_is_svg: true
          },
          {
            field_3_value: 'Reihenhaus',
            field_3_is_svg: true,
            field_3_value_id: 'RH',
            field_3_icon: `
            <svg width="65px" height="45px" viewBox="0 0 65 45" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <g id="Icon-RH" transform="translate(0.5 0.5)">
                <path d="M27.433 27L0 27L0 0L27.433 0L27.433 27ZM3.943 11L10.943 11L10.943 4L3.943 4L3.943 11ZM15.943 11L22.943 11L22.943 4L15.943 4L15.943 11Z" transform="translate(4.469 16.5)" id="Stroke-1" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M27.492 27L0 27L0 0L27.492 0L27.492 27ZM4.506 11L11.506 11L11.506 4L4.506 4L4.506 11ZM16.506 11L23.506 11L23.506 4L16.506 4L16.506 11Z" transform="translate(31.906 16.5)" id="Stroke-3" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M31.5 11.293L23.604 0L11.887 0L0 17" transform="translate(0.5 0.5)" id="Stroke-4" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M31.5 17L19.612 0L7.896 0L0 11.293" transform="translate(32 0.5)" id="Stroke-5" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
            `,
            field_3_icon_on: `
            <svg width="65px" height="45px" viewBox="0 0 65 45" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <g id="Icon-RH-Copy" transform="translate(0.5 0.5)">
                <path d="M27.433 27L0 27L0 0L27.433 0L27.433 27ZM3.943 11L10.943 11L10.943 4L3.943 4L3.943 11ZM15.943 11L22.943 11L22.943 4L15.943 4L15.943 11Z" transform="translate(4.469 16.5)" id="Stroke-1" fill="#primary" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M27.492 27L0 27L0 0L27.492 0L27.492 27ZM4.506 11L11.506 11L11.506 4L4.506 4L4.506 11ZM16.506 11L23.506 11L23.506 4L16.506 4L16.506 11Z" transform="translate(31.906 16.5)" id="Stroke-3" fill="none" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M31.5 11.293L23.604 0L11.887 0L0 17" transform="translate(0.5 0.5)" id="Stroke-4" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M31.5 17L19.612 0L7.896 0L0 11.293" transform="translate(32 0.5)" id="Stroke-5" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
            `
          },
          {
            field_4_value: 'Wohnung',
            field_4_value_id: 'WHG',
            field_4_is_svg: true,
            field_4_icon: `
            <svg width="57px" height="61px" viewBox="0 0 57 61" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <g id="Icon-WNG" transform="translate(0.5 0.5)">
                <path d="M0 43L47 43L47 0L0 0L0 43Z" transform="translate(4.5 16.5)" id="Stroke-1" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0 17L11.786 0L43.214 0L55 17" transform="translate(0.5 0.5)" id="Stroke-3" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47 15L0 15L0 0L47 0L47 15ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11Z" transform="translate(4.5 16.5)" id="Stroke-5" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0 5L4 5L4 0L0 0L0 5Z" transform="translate(51.5 26.5)" id="Stroke-6" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0 2L4 2L4 0L0 0L0 2Z" transform="translate(51.5 24.5)" id="Stroke-8" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
            `,
            field_4_icon_on: `
            <svg width="57px" height="61px" viewBox="0 0 57 61" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <g id="Icon-WNG-Copy" transform="translate(0.5 0.5)">
                <path d="M0 43L47 43L47 0L0 0L0 43Z" transform="translate(4.5 16.5)" id="Stroke-1" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0 17L11.786 0L43.214 0L55 17" transform="translate(0.5 0.5)" id="Stroke-3" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47 15L0 15L0 0L47 0L47 15ZM8 11L19 11L19 4L8 4L8 11ZM28 11L39 11L39 4L28 4L28 11Z" transform="translate(4.5 16.5)" id="Stroke-5" fill="#primary" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0 5L4 5L4 0L0 0L0 5Z" transform="translate(51.5 26.5)" id="Stroke-6" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0 2L4 2L4 0L0 0L0 2Z" transform="translate(51.5 24.5)" id="Stroke-8" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>
            `
          }
        ]
      }
    ]
  },
  {
    config: {
      head: 'Baujahr',
      mandatory: true // e.g. used by main-content to determine if closable without choosing
    },
    elements: [
      {
        id: 'u8x9g3bdxaq',
        field_x_fill_by_function: '',
        calculation: {
          type: 'calcServerSide',
          subType: 'oneValueId',
          topic: 'YEAR'
        },
        order: 2,
        type: 'multi',
        title: '',
        question: 'Baujahr',
        selectSubType: 'single',
        presentation: 'slider',
        choiceIsRelevantToPriceView: 'perhaps',
        // if chooses as last, get rid of "Bitte wählen Sie alle 4 Fragen ..."
        questionLong: 'Wann wurde Ihre Wohnung /Ihr Haus gebaut?',
        field_1_value: 'bis 1918',
        field_1_value_id: '1918',
        field_2_value: '1919 - 1948',
        field_2_value_id: '1919',
        field_3_value: '1949 - 1978',
        field_3_value_id: '1949',
        field_4_value: '1979 - 1990',
        field_4_value_id: '1979',
        field_4_default: true,
        field_5_value: '1991 - 2000',
        field_5_value_id: '1991',
        field_6_value: '2001 - 2008',
        field_6_value_id: '2001',
        field_7_value: '2009 - 2017',
        field_7_value_id: '2009',

        field_8_value: 'Neubau',
        field_8_value_id: '-1'
      }
    ]
  },
  {
    config: {
      head: 'Fläche',
      mandatory: true // e.g. used by main-content to determine if closable without choosing
    },
    elements: [
      {
        id: 'c3v3bdxaq',
        calculation: {
          type: 'calcServerSide',
          subType: 'oneValueId',
          topic: 'AREA'
        },
        choiceIsRelevantToPriceView: 'perhaps',
        // if chooses as last, get rid of "Bitte wählen Sie alle 4 Fragen ..."
        mandatory: true, // e.g. used by main-content to determine if closable without choosing
        order: 3,
        question: 'Fläche',
        questionLong: 'Wie groß ist Ihre Wohnung /Ihr Haus?',
        type: 'multi',
        title: '',
        selectSubType: 'single',
        presentation: 'slider',
        field_x_fill_by_function: 'housingArea'
      }
    ]
  },
  {
    config: {
      head: 'Heizart',
      mandatory: true // e.g. used by main-content to determine if closable without choosing
    },
    elements: [
      {
        id: 'd0d3bdxaq',
        field_x_fill_by_function: '',
        calculation: {
          type: 'calcServerSide',
          subType: 'oneValueId',
          topic: 'HEATING_TYPE'
        },
        order: 4,
        type: 'multi',
        choiceIsRelevantToPriceView: 'heating',
        title: '',
        min: 1,
        question: 'Heizart',
        questionLong: 'Womit heizen Sie aktuell?',
        selectSubType: 'single',
        presentation: 'icon',
        options: [
          {
            field_1_value: 'Nah-/Fernwärme',
            field_1_value_id: 'LDH',
            field_1_default: true,
            field_1_icon: `<svg width="57px" height="49px" viewBox="0 0 57 49" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Fernwärme" transform="translate(0.5 0.5)">
              <path d="M0 17L11.786 0L43.214 0L55 17" transform="translate(0.5 0.5)" id="Stroke-1" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.5C3 2.328 2.328 3 1.5 3C0.672 3 0 2.328 0 1.5C0 0.672 0.672 0 1.5 0C2.328 0 3 0.672 3 1.5Z" transform="translate(33.5 20.4927)" id="Stroke-3" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.75 0L0 0L0 11L31 11" transform="translate(0.5 36.5)" id="Stroke-5" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 27L47 27L47 0L0 0L0 27Z" transform="translate(4.5 16.5)" id="Stroke-7" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(8.5 20.4926)" id="Stroke-9" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(13.5 20.4926)" id="Stroke-10" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(18.5 20.4926)" id="Stroke-11" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(23.5 20.4926)" id="Stroke-12" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(28.5 20.4926)" id="Stroke-13" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_1_icon_on: `<svg width="57px" height="49px" viewBox="0 0 57 49" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Fernwärme-Copy" transform="translate(0.5 0.5)">
              <path d="M0 17L11.786 0L43.214 0L55 17" transform="translate(0.5 0.5)" id="Stroke-1" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.5C3 2.328 2.328 3 1.5 3C0.672 3 0 2.328 0 1.5C0 0.672 0.672 0 1.5 0C2.328 0 3 0.672 3 1.5Z" transform="translate(33.5 20.4927)" id="Stroke-3" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7.75 0L0 0L0 11L31 11" transform="translate(0.5 36.5)" id="Stroke-5" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 27L47 27L47 0L0 0L0 27Z" transform="translate(4.5 16.5)" id="Stroke-7" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(8.5 20.4926)" id="Stroke-9" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(13.5 20.4926)" id="Stroke-10" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(18.5 20.4926)" id="Stroke-11" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(23.5 20.4926)" id="Stroke-12" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3 1.502C3 0.672 2.328 0 1.5 0C0.672 0 0 0.672 0 1.502C0 1.511 0.002 1.519 0.003 1.528L0 1.528L0 17.558L0.006 17.558C0.039 18.359 0.692 19 1.5 19C2.308 19 2.961 18.359 2.994 17.558L3 17.558L3 1.528L2.997 1.528C2.998 1.519 3 1.511 3 1.502Z" transform="translate(28.5 20.4926)" id="Stroke-13" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_1_is_svg: true
          },
          {
            field_2_value: 'Erdgas',
            field_2_value_id: 'GAS',
            field_2_icon: `<svg width="25px" height="45px" viewBox="0 0 25 45" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <g id="Icon-Erdgas" transform="translate(0.5 0.5)">
                <path d="M0 3L23 3L23 0L0 0L0 3Z" transform="translate(0.5 40.4316)" id="Stroke-1" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.5 0C11.5 0 0 16.89 0 23.333C0 29.459 4.657 34.473 10.573 34.953C6.315 34.536 3 31.285 3 27.333C3 23.099 11.5 12 11.5 12C11.5 12 20 23.099 20 27.333C20 31.285 16.685 34.536 12.427 34.953C18.343 34.473 23 29.459 23 23.333C23 16.89 11.5 0 11.5 0Z" transform="translate(0.5 0.5)" id="Stroke-3" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0 0L1.854 0" transform="translate(11.0732 35.4526)" id="Stroke-4" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>`,
            field_2_icon_on: `<svg width="25px" height="45px" viewBox="0 0 25 45" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
              <desc>Created with Lunacy</desc>
              <g id="Icon-Erdgas-Copy" transform="translate(0.5 0.5)">
                <path d="M0 3L23 3L23 0L0 0L0 3Z" transform="translate(0.5 40.4316)" id="Stroke-1" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M11.5 0C11.5 0 0 16.89 0 23.333C0 29.459 4.657 34.473 10.573 34.953C6.315 34.536 3 31.285 3 27.333C3 23.099 11.5 12 11.5 12C11.5 12 20 23.099 20 27.333C20 31.285 16.685 34.536 12.427 34.953C18.343 34.473 23 29.459 23 23.333C23 16.89 11.5 0 11.5 0Z" transform="translate(0.5 0.5)" id="Stroke-3" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M0 0L1.854 0" transform="translate(11.0732 35.4526)" id="Stroke-4" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              </g>
            </svg>`,
            field_2_is_svg: true
          },
          {
            field_3_value: 'Heizöl',
            field_3_value_id: 'OIL',
            field_3_icon: `<svg width="24px" height="36px" viewBox="0 0 24 36" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Heizöl" transform="translate(0.5 0.5)">
              <path d="M0 23.333C0 16.89 11.5 0 11.5 0C11.5 0 23 16.89 23 23.333C23 29.777 17.851 35 11.5 35C5.149 35 0 29.777 0 23.333ZM16 25.5C16 26.328 16.671 27 17.5 27C18.328 27 19 26.328 19 25.5C19 24.672 18.328 24 17.5 24C16.671 24 16 24.672 16 25.5Z" id="Stroke-1" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_3_icon_on: `<svg width="24px" height="36px" viewBox="0 0 24 36" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Heizöl-Copy" transform="translate(0.5 0.5)">
              <path d="M0 23.333C0 16.89 11.5 0 11.5 0C11.5 0 23 16.89 23 23.333C23 29.777 17.851 35 11.5 35C5.149 35 0 29.777 0 23.333ZM16 25.5C16 26.328 16.671 27 17.5 27C18.328 27 19 26.328 19 25.5C19 24.672 18.328 24 17.5 24C16.671 24 16 24.672 16 25.5Z" id="Stroke-1" fill="#primary" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_3_is_svg: true
          },
          {
            field_4_value: 'Wärmepumpe',
            field_4_value_id: 'HP',
            field_4_icon: `<svg width="57px" height="29px" viewBox="0 0 57 29" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Wärmepumpe" transform="translate(0.5 0.5)">
              <path d="M47 27L0 27L0 0L47 0L47 27ZM4 13.5C4 18.747 8.253 23 13.5 23C18.745 23 23 18.747 23 13.5C23 8.253 18.745 4 13.5 4C8.253 4 4 8.253 4 13.5Z" transform="translate(8.5 0.5)" id="Stroke-1" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3.5 1.75L3.5 0L0 0L0 7L3.5 7L3.5 5.25L8 5.25L8 1.75L3.5 1.75Z" transform="translate(0.5 2.5)" id="Stroke-3" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(40.5 24)" id="Stroke-5" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(40.5 12)" id="Stroke-7" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(40.5 16)" id="Stroke-9" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(40.5 20)" id="Stroke-11" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.72205 0C7.55005 0 8.22205 0.784 8.22205 1.75C8.22205 2.71055 6.73894 6.95173 6.7222 6.99959C6.74824 6.97976 10.3354 4.24861 11.2511 3.9507C12.1701 3.6537 13.1231 4.0487 13.3801 4.8377C13.6361 5.6247 13.0981 6.5057 12.1791 6.8047C11.2616 7.10187 6.74723 7.00027 6.72216 6.9997L6.72205 7C6.72205 7 5.22205 2.716 5.22205 1.75C5.22205 0.784 5.89305 0 6.72205 0ZM0.0637535 4.8372C0.319754 4.0492 1.27275 3.6532 2.19175 3.9512C3.11075 4.2502 6.72175 7.0002 6.72175 7.0002C6.72175 7.0002 2.18375 7.1032 1.26475 6.8052C0.345754 6.5062 -0.192246 5.6252 0.0637535 4.8372ZM10.8362 12.6649C10.1662 13.1509 9.16215 12.9109 8.59415 12.1299C8.02995 11.3518 6.735 7.04267 6.72225 7.00022C6.71939 7.00975 5.41663 11.3481 4.85025 12.1302C4.28125 12.9112 3.27725 13.1512 2.60725 12.6642C1.93725 12.1772 1.85525 11.1482 2.42225 10.3662C2.98977 9.58587 6.71588 7.00462 6.72225 7.00021L6.72215 6.9999C6.72215 6.9999 10.4532 9.5839 11.0222 10.3649C11.5892 11.1479 11.5072 12.1769 10.8362 12.6649Z" transform="translate(15.27795 7)" id="Combined-Shape" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 27L19 27L19 0L0 0L0 27Z" transform="translate(36.5 0.5)" id="Stroke-23" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_4_icon_on: `<svg width="57px" height="29px" viewBox="0 0 57 29" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Wärmepumpe-Copy" transform="translate(0.5 0.5)">
              <path d="M47 27L0 27L0 0L47 0L47 27ZM4 13.5C4 18.747 8.253 23 13.5 23C18.745 23 23 18.747 23 13.5C23 8.253 18.745 4 13.5 4C8.253 4 4 8.253 4 13.5Z" transform="translate(8.5 0.5)" id="Stroke-1" fill="#primary" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M3.5 1.75L3.5 0L0 0L0 7L3.5 7L3.5 5.25L8 5.25L8 1.75L3.5 1.75Z" transform="translate(0.5 2.5)" id="Stroke-3" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(40.5 24)" id="Stroke-5" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(40.5 12)" id="Stroke-7" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(40.5 16)" id="Stroke-9" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(40.5 20)" id="Stroke-11" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M6.72205 0C7.55005 0 8.22205 0.784 8.22205 1.75C8.22205 2.71055 6.73894 6.95173 6.7222 6.99959C6.74824 6.97976 10.3354 4.24861 11.2511 3.9507C12.1701 3.6537 13.1231 4.0487 13.3801 4.8377C13.6361 5.6247 13.0981 6.5057 12.1791 6.8047C11.2616 7.10187 6.74723 7.00027 6.72216 6.9997L6.72205 7C6.72205 7 5.22205 2.716 5.22205 1.75C5.22205 0.784 5.89305 0 6.72205 0ZM0.0637535 4.8372C0.319754 4.0492 1.27275 3.6532 2.19175 3.9512C3.11075 4.2502 6.72175 7.0002 6.72175 7.0002C6.72175 7.0002 2.18375 7.1032 1.26475 6.8052C0.345754 6.5062 -0.192246 5.6252 0.0637535 4.8372ZM10.8362 12.6649C10.1662 13.1509 9.16215 12.9109 8.59415 12.1299C8.02995 11.3518 6.735 7.04267 6.72225 7.00022C6.71939 7.00975 5.41663 11.3481 4.85025 12.1302C4.28125 12.9112 3.27725 13.1512 2.60725 12.6642C1.93725 12.1772 1.85525 11.1482 2.42225 10.3662C2.98977 9.58587 6.71588 7.00462 6.72225 7.00021L6.72215 6.9999C6.72215 6.9999 10.4532 9.5839 11.0222 10.3649C11.5892 11.1479 11.5072 12.1769 10.8362 12.6649Z" transform="translate(15.27795 7)" id="Combined-Shape" fill="none" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 27L19 27L19 0L0 0L0 27Z" transform="translate(36.5 0.5)" id="Stroke-23" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_4_is_svg: true
          },
          {
            field_5_value: 'Elektroheizung',
            field_5_value_id: 'ESH',
            field_5_icon: `<svg width="25px" height="45px" viewBox="0 0 25 45" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Elektrospeicher" transform="translate(0.5 0.5)">
              <path d="M23 0L23 32L0 32L0 0L23 0ZM8 7.5C8 9.433 9.567 11 11.5 11C13.433 11 15 9.433 15 7.5C15 5.567 13.433 4 11.5 4C9.567 4 8 5.567 8 7.5Z" transform="translate(0.5 0.5)" id="Stroke-1" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M11.5 11C5.149 11 0 6.075 0 0L23 0C23 6.075 17.851 11 11.5 11ZM10 5.5C10 6.328 10.671 7 11.5 7C12.328 7 13 6.328 13 5.5C13 4.672 12.328 4 11.5 4C10.671 4 10 4.672 10 5.5Z" transform="translate(0.5 32.5)" id="Stroke-3" fill="none" fill-rule="evenodd" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(4.5 28)" id="Stroke-5" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(4.5 24)" id="Stroke-7" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(4.5 20)" id="Stroke-9" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_5_icon_on: `<svg width="25px" height="45px" viewBox="0 0 25 45" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Elektrospeicher-Copy" transform="translate(0.5 0.5)">
              <path d="M23 0L23 32L0 32L0 0L23 0ZM8 7.5C8 9.433 9.567 11 11.5 11C13.433 11 15 9.433 15 7.5C15 5.567 13.433 4 11.5 4C9.567 4 8 5.567 8 7.5Z" transform="translate(0.5 0.5)" id="Stroke-1" fill="#primary" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M11.5 11C5.149 11 0 6.075 0 0L23 0C23 6.075 17.851 11 11.5 11ZM10 5.5C10 6.328 10.671 7 11.5 7C12.328 7 13 6.328 13 5.5C13 4.672 12.328 4 11.5 4C10.671 4 10 4.672 10 5.5Z" transform="translate(0.5 32.5)" id="Stroke-3" fill="#primary" fill-rule="evenodd" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(4.5 28)" id="Stroke-5" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(4.5 24)" id="Stroke-7" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(4.5 20)" id="Stroke-9" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_5_is_svg: true
          },
          {
            field_6_value: 'Holz',
            field_6_value_id: 'WD',
            field_6_icon: `<svg width="49px" height="24px" viewBox="0 0 49 24" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Holz" transform="translate(0.5 0.5)">
              <path d="M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z" transform="translate(0.5 8.4961)" id="Stroke-1" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M4.883 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L8.89 11C8.647 10.25 8.506 9.403 8.506 8.504C8.506 5.467 10.073 3.004 12.006 3.004L7.999 3.004C6.639 3.004 5.462 1.781 4.883 0Z" transform="translate(3.9998 8.4961)" id="Stroke-3" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M27.625 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L27.625 11C29.765 11 31.5 8.537 31.5 5.5C31.5 2.463 29.765 0 27.625 0Z" transform="translate(16.0059 11.5)" id="Stroke-5" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(24.3809 15)" id="Stroke-7" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(32.2559 19.167)" id="Stroke-9" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z" transform="translate(12.5059 11.5)" id="Stroke-11" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M27.625 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L27.625 11C29.765 11 31.5 8.537 31.5 5.5C31.5 2.463 29.765 0 27.625 0Z" transform="translate(11.999 0.5)" id="Stroke-13" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(28.249 8.167)" id="Stroke-15" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(20.374 4)" id="Stroke-17" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z" transform="translate(8.499 0.5)" id="Stroke-19" fill="none" stroke="#B9B9B9" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_6_icon_on: `<svg width="49px" height="24px" viewBox="0 0 49 24" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
            <desc>Created with Lunacy</desc>
            <g id="Icon-Holz-Copy" transform="translate(0.5 0.5)">
              <path d="M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z" transform="translate(0.5 8.4961)" id="Stroke-1" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M4.883 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L8.89 11C8.647 10.25 8.506 9.403 8.506 8.504C8.506 5.467 10.073 3.004 12.006 3.004L7.999 3.004C6.639 3.004 5.462 1.781 4.883 0Z" transform="translate(3.9998 8.4961)" id="Stroke-3" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M27.625 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L27.625 11C29.765 11 31.5 8.537 31.5 5.5C31.5 2.463 29.765 0 27.625 0Z" transform="translate(16.0059 11.5)" id="Stroke-5" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(24.3809 15)" id="Stroke-7" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(32.2559 19.167)" id="Stroke-9" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z" transform="translate(12.5059 11.5)" id="Stroke-11" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M27.625 0L0 0C1.933 0 3.5 2.463 3.5 5.5C3.5 8.537 1.933 11 0 11L27.625 11C29.765 11 31.5 8.537 31.5 5.5C31.5 2.463 29.765 0 27.625 0Z" transform="translate(11.999 0.5)" id="Stroke-13" fill="#primary" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L11 0" transform="translate(28.249 8.167)" id="Stroke-15" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M0 0L15 0" transform="translate(20.374 4)" id="Stroke-17" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M7 5.5C7 8.537 5.433 11 3.5 11C1.567 11 0 8.537 0 5.5C0 2.463 1.567 0 3.5 0C5.433 0 7 2.463 7 5.5Z" transform="translate(8.499 0.5)" id="Stroke-19" fill="none" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
            </g>
          </svg>`,
            field_6_is_svg: true
          }
        ]
      },
      {
        id: 'zu6dfghhteh5',
        // "calculation":{type:"calcServerSide", subType:"oneValueId", topic:"BUILDING"},
        order: 5,
        type: 'multi',
        title: '',
        text: '',
        min: 1,
        questionLong: 'Gasanschluß vorhanden?',
        selectSubType: 'single',
        presentation: 'cloud',
        field_1_value: 'Weiß nicht',
        field_1_default: true,
        field_2_value: 'Ja',
        field_3_value: 'Nein',
        previewHide: true,
        viewSettings: {
          noRenderIfOtherElementHasNotValue: {
            otherId: 'd0d3bdxaq',
            otherContentNum: 2,
            rendered: false
            // <<init with false,
            // if set to true > rerender/resize complete view to make space for that element
          }
        }
      }
    ]
  }
]

export const BuildingYearSteps: string[] = [
  'bis 1918',
  '1919 - 1948',
  '1949 - 1978',
  '1979 - 1990',
  '1991 - 2000',
  '2001 - 2008',
  '2009 - 2017',
  'Neubau'
]

export const convertAreaToSteps = () => {
  const steps: string[] = []

  for (let i = 30; i <= 75; i = i + 5) {
    steps.push(`${i} m²`)
  }

  for (let i = 85; i <= 350; i = i + 10) {
    steps.push(`${i} m²`)
  }

  for (let i = 400; i <= 800; i = i + 50) {
    steps.push(`${i} m²`)
  }

  steps.push('> 800 m²')

  return steps
}

export const BuildingAreaSteps = convertAreaToSteps()

export const AccordiongActiveStates = [
  { title: 'BUILDING', active: true },
  { title: 'YEAR', active: false },
  { title: 'AREA', active: false },
  { title: 'HEATING_TYPE', active: false }
]

export type StandardAnswersType = { question: string; name: string; value: string }

// export const HeatingTypeOptions: SelectOptionType[] = [
//   {
//     value: 'Nah-/Fernwärme',
//     checked: false,
//     svgName: SVGNames.NahFernWarm,
//     value_id: 'LDH'
//   },
//   {
//     value: 'Erdgas',
//     checked: false,
//     svgName: SVGNames.Erdgas,
//     value_id: 'GAS'
//   },
//   {
//     value: 'Heizöl',
//     checked: false,
//     svgName: SVGNames.HeizOil,
//     value_id: 'OIL'
//   },
//   {
//     value: 'Wärmepumpe',
//     checked: false,
//     svgName: SVGNames.Warmepumpe,
//     value_id: 'HP'
//   },
//   {
//     value: 'Elektroheizung',
//     checked: false,
//     svgName: SVGNames.Elektroheizung,
//     value_id: 'ESH'
//   },
//   {
//     value: 'Holz',
//     checked: false,
//     svgName: SVGNames.Holz,
//     value_id: 'WD'
//   }
// ]

// export const BuildingTypeOptions: SelectOptionType[] = [
//   {
//     value: 'Einfamilienhaus',
//     checked: false,
//     svgName: SVGNames.Einfamilienhaus,
//     value_id: 'EFH'
//   },
//   {
//     value: 'Mehrfamilienhaus',
//     checked: false,
//     svgName: SVGNames.Mehrfamilienhaus,
//     value_id: 'MFH'
//   },
//   {
//     value: 'Reihenhaus',
//     checked: false,
//     svgName: SVGNames.Reihenhaus,
//     value_id: 'RH'
//   },
//   {
//     value: 'Wohnung',
//     checked: false,
//     svgName: SVGNames.Wohnung,
//     value_id: 'WHG'
//   }
// ]

// export const GasConnectionOptions: SelectOptionType[] = [
//   {
//     value: 'Weiß nicht',
//     checked: false
//   },
//   {
//     value: 'Ja',
//     checked: false
//   },
//   {
//     value: 'Nein',
//     checked: false
//   }
// ]
