/* eslint-disable array-callback-return */
import { AdditionalAnswerType, StandardAnswersType } from '../../../store/static'
import { SelectOptionType } from '../../atoms'

type Field = {
  [key: string]: string
}
export const transferFieldsToSelectOption = (fields: Field) => {
  const options: SelectOptionType[] = []
  Object.keys(fields).map((key) => {
    if (key.includes('value')) {
      options.push({ value: fields[key], checked: false })
    }
  })
  return options
}

export const transferFieldsToSteps = (fields: Field) => {
  const steps: string[] = []
  Object.keys(fields).map((key) => {
    if (key.includes('value')) {
      steps.push(fields[key])
    }
  })
  return steps
}

export const reformStandardAnswersForEmail = (standardAnswers: StandardAnswersType[]) => {
  const answersForEmail: { question: string; answer: string[] }[] = []
  standardAnswers.forEach((answer: StandardAnswersType) => {
    const reformedAnswer: { question: string; answer: string[] } = { question: '', answer: [] }
    if (answer.value !== '') {
      reformedAnswer.question = answer.question
      reformedAnswer.answer.push(answer.value)
      answersForEmail.push(reformedAnswer)
    }
  })
  console.log('_______ reformed standard answer: ', answersForEmail)
  return answersForEmail
}

export const reformAdditionalAnswersForEmail = (additionalAnswers: AdditionalAnswerType[]) => {
  const answersForEmail: { question: string; answer: string[] }[] = []
  additionalAnswers.forEach((answer: AdditionalAnswerType) => {
    const reformedAnswer: { question: string; answer: string[] } = { question: '', answer: [] }
    reformedAnswer.question = answer.question
    if (typeof answer.value === 'string') {
      reformedAnswer.answer.push(answer.value)
    } else {
      reformedAnswer.answer = [...answer.value]
    }
    answersForEmail.push(reformedAnswer)
  })
  console.log('_______ reformed additional answer: ', answersForEmail)
  return answersForEmail
}
