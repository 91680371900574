import React, { useContext, useState, useEffect } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { readableColor } from 'polished'
import { AppTheme, Displays } from '../../../theme'
import { AppStoreContext, getUploadURL } from '../../../utils'
import {
  Button,
  Card,
  Space,
  Typography,
  SVGIcon,
  SVGNames,
  Divider,
  Image,
  FlexContainer
} from '../../atoms'

interface ResultMobileProps {
  hidden?: boolean
  onHide(state: boolean): void
}
export const ResultMobile: React.FC<ResultMobileProps> = ({ hidden, onHide }) => {
  const store = useContext(AppStoreContext)
  const [logoURL, setLogoURL] = useState('')
  const [initialClaim, setInitialClaim] = useState('')
  const [resultText, setResultText] = useState('')

  const fontColor = readableColor(
    AppTheme.colors.secondary,
    AppTheme.colors.stronggray,
    AppTheme.colors.white
  )

  useEffect(() => {
    if (!!store) {
      setLogoURL(getUploadURL + store.ciSettings.settings_sw_file_logo)
      setInitialClaim(store.wtSettings.pricePage.text.textUnderLogo)
      setResultText(store.wtSettings.pricePage.text.show_if_no_price)
    }
  }, [getUploadURL, store])

  const handleHideResult = () => {
    if (onHide) {
      onHide(false)
    }
    store!.questionStore.closeAllAccordions()
    store!.questionStore.hideResultMobileAfterTriggered(-1)
  }

  const handleOpenAdditionalQuestions = () => {
    store!.questionStore.openAdditionalQuestions()
    store!.questionStore.showResultMobile(false)
    store!.questionStore.hideResultMobileAfterTriggered(4)
  }

  return (
    <ResultMobileContainer hidden={hidden}>
      <Card>
        <Space p={`${AppTheme.spacingSize.XL}px`}>
          <TitelContainer>
            <Typography fontSize={`${AppTheme.fonts.sizes.size_H2}px`}>{'Übersicht'}</Typography>
            <IconContainer onClick={handleHideResult} bgColor={AppTheme.colors.secondary}>
              <SVGIcon name={SVGNames.ArrowDown} />
            </IconContainer>
          </TitelContainer>
          <Space
            mt={`${AppTheme.spacingSize.M}px`}
            mb={`${AppTheme.spacingSize.L}px`}
            height='auto'>
            <Divider />
          </Space>

          <ContentContainer>
            <Space pb={`${AppTheme.spacingSize.M}px`} height='auto'>
              <ImageContainer>
                <Image src={logoURL} display='block' maxWidth='305px' width='305px' height='100%' />
              </ImageContainer>
            </Space>
            <TextContainer>
              <Space my={`${AppTheme.spacingSize.S}px`}>
                <Typography fontSize={`${AppTheme.fonts.sizes.size_H2}px`}>
                  {initialClaim}
                </Typography>
              </Space>
              <Space my={`${AppTheme.spacingSize.S}px`}>
                <FlexContainer
                  style={{ padding: '20px', borderRadius: '5px', width: '100%' }}
                  bgColor={AppTheme.colors.secondary}
                  flexDirection={'column'}
                  alignItems={'center'}
                  justifyContent={
                    store!.questionStore.allStandardQuestionAnswered
                      ? 'space-between'
                      : 'flex-start'
                  }>
                  {!!store && store.questionStore.allStandardQuestionAnswered ? (
                    <>
                      <Typography
                        color={fontColor}
                        textAlignment='center'
                        lineHeight='2rem'
                        width='auto'
                        fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
                        display={Displays.INLINE_BLOCK}>
                        {store.wtSettings.pricePage.text.show_if_price}
                      </Typography>
                      <Typography
                        color={fontColor}
                        lineHeight='2rem'
                        width='auto'
                        fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
                        display={Displays.INLINE_BLOCK}>
                        {`${store.wtSettings.pricePage.text.show_before_price} ${store.pricePerMonthForPreview}€${store.wtSettings.pricePage.text.show_after_price}`}
                      </Typography>
                    </>
                  ) : (
                    <Typography fontSize={`${AppTheme.fonts.sizes.size_H2}px`} color={fontColor}>
                      {resultText}
                    </Typography>
                  )}
                </FlexContainer>
              </Space>
            </TextContainer>

            {!!store && store.questionStore.allStandardQuestionAnswered && (
              <Space mt={`${AppTheme.spacingSize.L}px`}>
                <FlexContainer
                  flexDirection='row'
                  justifyContent='space-between'
                  alignItems='center'>
                  <Button
                    visible={!store.questionStore.showAdditionalQuestions}
                    animated
                    hasBorder={false}
                    maxWidth='100px'
                    isUnderlined
                    onClick={handleOpenAdditionalQuestions}>
                    Genaueres Ergebnis?
                  </Button>
                  <Button hasBorder onClick={() => store!.uiStore.triggerContactMobile(true)}>
                    {'Angebot'}
                  </Button>
                </FlexContainer>
              </Space>
            )}
            <TextContainer className='heat_type_hint'>
              <Space my={`${AppTheme.spacingSize.M}px`}>
                <Typography fontSize={`${AppTheme.fonts.sizes.size_Paragraph}px`}>
                  {!!store ? store.questionStore.heatingTypeText : ''}
                </Typography>
              </Space>
            </TextContainer>
          </ContentContainer>
        </Space>
      </Card>
    </ResultMobileContainer>
  )
}

const ResultMobileContainer = styled.div<Partial<ResultMobileProps>>`
  position: absolute;
  width: 100%;
  height: calc(100% - 44px);
  z-index: 1000;
  top: 100%;
  ${(props) => (props.hidden ? animationDown : animationUp)};
`
const slideUp = keyframes`
  from { top: 100%; }
  to { top: 44px; }
`
const slideDown = keyframes`
  from { top: 44px; }
  to { top: 100%; }
`

const animationUp = css`
  animation: ${slideUp} 0.6s ease 1 forwards;
`

const animationDown = css`
  animation: ${slideDown} 0.6s ease 1 forwards;
`

const TitelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding: 0px 12px;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TextContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  color: ${(props) => props.theme.colors.darkgray};
`

interface IconContainerProps {
  bgColor?: string
}

export const IconContainer = styled.div<IconContainerProps>`
  cursor: inherit;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
  display: block;
  width: 28px;
  height: 28px;
  position: relative;
`

export default ResultMobile
