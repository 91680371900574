import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import ProgressUnit from './component_unit'

interface ProgressProps {
  length: number
  completeNumber: number
}
export const Progress: React.FC<ProgressProps> = ({ length, completeNumber }) => {
  const progressBarRef = useRef<HTMLDivElement>(null)

  const [totalHeight, setTotalHeight] = useState(0)
  const [completeNum, setCompleteNum] = useState(completeNumber)

  const createProgressBar = () => {
    const progressBar = []
    for (let i = 0; i < length; i++) {
      progressBar.push(
        <ProgressUnit
          key={i}
          height={i === length - 1 ? `${totalHeight / length}px` : `${totalHeight / length - 1}px`}
          isCompleted={i < completeNum}
          isFirst={i === length - 1}
        />
      )
    }
    return progressBar
  }

  useEffect(() => {
    if (progressBarRef.current) {
      setTotalHeight(progressBarRef.current.clientHeight)
    }
    if (completeNumber !== completeNum) {
      setCompleteNum(completeNumber)
    }
  }, [completeNum, completeNumber, totalHeight])

  return <ProgressBar ref={progressBarRef}>{createProgressBar()}</ProgressBar>
}

const ProgressBar = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column-reverse;
  height: ${(props) =>
    `calc( ${props.theme.window.innerHeight}px - 44px - ${props.theme.spacingSize.XL}px)`};
  box-sizing: border-box;
  /* box-shadow: rgb(0, 0, 0) 0px 0px 2px inset; */
  /* &::before {
    content: '';
    z-index: 1000;
    box-shadow: rgb(0, 0, 0, 0.3) 6px 0 6px -6px inset;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */
`

export default Progress
