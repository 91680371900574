const uploadURL = (): string => {
  const isProd = process.env.NODE_ENV === 'production'
  const HOST: string = process.env.HOST_URL as string
  const PORT: number = parseInt(process.env.app_port as string, 10)
  // const PORT: number = parseInt(process.env.app_port as string, 10)
  // const bffURL = isProd ? `${HOST}/api/` : `${HOST}/api/`
  const uploadURL = isProd ? (process.env.APP_UPLOADS_BASE_URL as string) : `${HOST}:${PORT}/api/`

  console.log('isProd: ', isProd, '\nbffURL:', uploadURL)

  return uploadURL
}

export const getUploadURL = uploadURL()
