import { SelectOptionType, SVGNames } from '../components/atoms'

export const WTSettings = {
  loaded: false,

  calculationServerParams: {
    // are stored client side and send to server for every calculation
    // could be done completly serverside, but that server needs to touch
    // db for every calculation
    buyCostFactor: 0,
    withTww: true,
    extraCostMonthlyWithTww: 0,
    // not used anymore, always 0, see StartCalulation comment in HeatToolCalculateFactory
    extraCostMonthlyWithoutTww: 0, // also not used anymore
    calcSurchargeWithTww: false,
    calcSurchargeWithoutTww: false
  },

  showPriceForHousing: {
    EFH: true,
    RH: true,
    WHG: true,
    MFH: true
  },
  heatingType: {
    LDH: {
      showPrice: true,
      procentualPriceExtra: 0,
      text:
        'Fernwärme: Sie bekommen ein hocheffizientes Markengerät und die Serviceleistung direkt dazu!'
    },
    GAS: {
      showPrice: true,
      procentualPriceExtra: 0,
      text: 'Gas: Sie bekommen ein hocheffizientes Markengerät und die Serviceleistung direkt dazu!'
    },
    OIL: {
      showPrice: true,
      procentualPriceExtra: 0,
      text: 'Öl: Sie bekommen ein hocheffizientes Markengerät und die Serviceleistung direkt dazu!'
    },
    HP: {
      showPrice: true,
      procentualPriceExtra: 0,
      text:
        'Wärmepumpe: Sie bekommen ein hocheffizientes Markengerät und die Serviceleistung direkt dazu!'
    },
    ESH: {
      showPrice: true,
      procentualPriceExtra: 0,
      text:
        'Elektrospeicher: Sie bekommen ein hocheffizientes Markengerät und die Serviceleistung direkt dazu!'
    },
    WD: {
      showPrice: true,
      procentualPriceExtra: 0,
      text:
        'Holz: Sie bekommen ein hocheffizientes Markengerät und die Serviceleistung direkt dazu!'
    }
  },
  basics: {
    text: {
      heatToolName: '',
      plz: []
    }
  },
  form: {
    crossSellingCheckboxes: [''],
    text: {
      sendSuccess:
        'Ihre Daten wurden erfolgreich an uns übermittelt. In Ihrem E-Mail Postfach finden Sie Ihr Produktname Angebot als PDF.',
      dataProtectionCheckboxText: 'Ich habe die Datenschutzerklärung gelesen und akzeptiert',
      dataProtectionUrl: '',
      dataImprintUrl: ''
    }
  },
  pricePage: {
    text: {
      textUnderLogo: 'Einfach zur neuen Heizung mit Beratung! textUnderLogo',
      contractingAdvantages:
        'Mit unserem Service brauchen Sie sich keine Sorgen über Wartungs-, Instandhaltungs- und Schornsteinfegerkosten machen. Die Stadtwerke stehen Ihnen in der Vertragslaufzeit für diese Arbeiten zur Verfügung. Dank Wartungsplan und Markengeräten sind Sie gegen Ausfälle abgesichert, von der Wartung bis zu einem Austausch der Anlage übernehmen die Stadtwerke die Kosten innerhalb der Vertragslaufzeit.',
      show_if_no_price_because_housing: 'Leider keine Live-Preisvorschau für diese Gebäudeart.',
      show_if_no_price_because_heating: 'Leider keine Live-Preisvorschau für diese Heizart.',
      show_if_no_price_because_heating_and_housing:
        'Leider keine Live-Preisvorschau für diese Gebäude- und Heizart.',
      show_if_no_price: 'Preisvorschau und Angebot: Bitte beantworten Sie die 4 Fragen.',
      show_if_exotic_params:
        'Was soll angezeigt werden, wenn Preisberechnung -1 ergibt (für exotische Parameter)?',
      show_if_price: 'Rundum-Sorglos-Heizen',

      show_before_price: 'ab ',
      show_after_price: '/Monat'
    },
    showComparison: true
  }
}

export const buildingTypes: SelectOptionType[] = [
  {
    value: 'Einfamilienhaus',
    checked: false,
    svgName: SVGNames.Einfamilienhaus,
    value_id: 'EFH'
  },
  {
    value: 'Mehrfamilienhaus',
    checked: false,
    svgName: SVGNames.Mehrfamilienhaus,
    value_id: 'MFH'
  },
  {
    value: 'Reihenhaus',
    checked: false,
    svgName: SVGNames.Reihenhaus,
    value_id: 'RH'
  },
  {
    value: 'Wohnung',
    checked: false,
    svgName: SVGNames.Wohnung,
    value_id: 'WHG'
  }
]

export const heatingTypeOptions: SelectOptionType[] = [
  {
    value: 'Nah-/Fernwärme',
    checked: false,
    svgName: SVGNames.NahFernWarm,
    value_id: 'LDH'
  },
  {
    value: 'Erdgas',
    checked: false,
    svgName: SVGNames.Erdgas,
    value_id: 'GAS'
  },
  {
    value: 'Heizöl',
    checked: false,
    svgName: SVGNames.HeizOil,
    value_id: 'OIL'
  },
  {
    value: 'Wärmepumpe',
    checked: false,
    svgName: SVGNames.Warmepumpe,
    value_id: 'HP'
  },
  {
    value: 'Elektroheizung',
    checked: false,
    svgName: SVGNames.Elektroheizung,
    value_id: 'ESH'
  },
  {
    value: 'Holz',
    checked: false,
    svgName: SVGNames.Holz,
    value_id: 'WD'
  }
]

export const gasConnectionOptions: SelectOptionType[] = [
  {
    value: 'Weiß nicht',
    checked: false
  },
  {
    value: 'Ja',
    checked: false
  },
  {
    value: 'Nein',
    checked: false
  }
]

export type AdditionalAnswerType = {
  question: string
  value: string | string[]
  order: number
  id: string
  cost?: {
    absolute?: number
    procentual?: number
  }
}
export type StandardAnswersType = { question: string; name: string; value: string }
