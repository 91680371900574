/* eslint-disable array-callback-return */
import AppStore from './ApplicationStore'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import {
  AdditionalAnswerType,
  buildingTypes,
  gasConnectionOptions,
  heatingTypeOptions,
  StandardAnswersType
} from './static'
import { BuildingYearSteps } from '../components/template'
import { SelectOptionType } from '../components/atoms'
import { BuildingAreaSteps } from '../components/template/Questions/static'
import { calculateCostValue, setValueId } from './utils'

class QuestionStore {
  appStore: typeof AppStore
  buildingTypeOptions: SelectOptionType[] = buildingTypes
  buildYear: string = ''
  buildingArea: string = ''
  heatingTypes: SelectOptionType[] = heatingTypeOptions
  gasConnectionTypes: SelectOptionType[] = gasConnectionOptions

  standardAnswers: StandardAnswersType[] = [
    { question: 'Gebäudeart', name: 'BUILDING', value: '' },
    { question: 'Baujahr', name: 'YEAR', value: '' },
    { question: 'Fläche', name: 'AREA', value: '' },
    { question: 'Heizart', name: 'HEATING_TYPE', value: '' },
    { question: 'Gasanschluß vorhanden?', name: 'GAS_CONNECTION', value: '' }
  ]

  calcServerSide: { [key: string]: string } = {
    BUILDING: '',
    YEAR: '',
    AREA: '',
    HEATING_TYPE: ''
  }
  additionalAnswers: AdditionalAnswerType[] = []
  accordionActiveStatesForStandard = [
    { title: 'BUILDING', active: true },
    { title: 'YEAR', active: false },
    { title: 'AREA', active: false },
    { title: 'HEATING_TYPE', active: false }
  ]
  accordionActiveStatesForAdditional: { title: string; active: boolean }[] = []
  standardAnswersNumber: number = 0
  additionalAnswerNumber: number = 0
  allStandardQuestionAnswered: boolean = false
  showAdditionalQuestions: boolean = false
  heatingTypeText: string = ''
  selectedHeatingTypeSetting: {
    procentualPriceExtra: number
    showPrice: boolean
    text: string
  } = {
    procentualPriceExtra: 0,
    showPrice: false,
    text: ''
  }

  openIndex: number = 0
  isResultMobileOpen: boolean = false
  isResultMobileTriggered: boolean = false
  allAccordionsClosed: boolean = false
  priceInfo = {
    showPrice: true,
    showPriceHeating: true, //used to determine showPrice
    showPriceHousing: true, //used to determine showPrice
    alternativeText: 'Leider keine Live-Preisvorschau: xxx.', //determined dynamically
    procentualPriceExtraHeating: 0
  }

  public constructor(appStore: typeof AppStore) {
    makeAutoObservable(this)
    this.appStore = appStore
  }

  /* ============= standard questions part =============== */
  updateStandardAnswers = (key: string, value: string) => {
    const answers: StandardAnswersType[] = this.standardAnswers
    const aktQuestion = answers.find((answer: StandardAnswersType) => answer.name === key)
    aktQuestion!.value = value
    // answers[key] = value
    if (key === 'HEATING_TYPE' || key === 'Heizart') {
      // get the id of heating type, this id will be key for other properties like showPricing
      this.calcServerSide.HEATING_TYPE = setValueId(this.heatingTypes, value)!
      // if the price will be shown according to selected heating type
      this.priceInfo.showPriceHeating = this.appStore.wtSettings.heatingType[
        this.calcServerSide.HEATING_TYPE
      ].showPrice
      this.priceInfo.procentualPriceExtraHeating = this.appStore.wtSettings.heatingType[
        this.calcServerSide.HEATING_TYPE
      ].procentualPriceExtra
      console.log(
        'show price for this heating:',
        toJS(this.appStore.wtSettings.heatingType[this.calcServerSide.HEATING_TYPE]),
        this.appStore.wtSettings.heatingType[this.calcServerSide.HEATING_TYPE].showPrice
      )

      this.heatingTypeText = this.setHeatingTypeHint(
        toJS(this.appStore.wtSettings.heatingType),
        value
      )
    } else if (key === 'Gebäudeart' || key === 'BUILDING') {
      // get the id of Building, this id will be key for other properties like showPricing
      this.calcServerSide.BUILDING = setValueId(this.buildingTypeOptions, value)!
      // if the price will be show according to selected building
      this.priceInfo.showPriceHousing = this.appStore.wtSettings.showPriceForHousing[
        this.calcServerSide.BUILDING
      ]
      console.log(
        'show price for this house:',
        this.appStore.wtSettings.showPriceForHousing[this.calcServerSide.BUILDING]
      )
    } else if (key === 'Baujahr' || key === 'YEAR') {
      if (value === 'Neubau') {
        this.calcServerSide.YEAR = '-1'
      } else if (value === 'bis 1918') {
        this.calcServerSide.YEAR = '1918'
      } else {
        this.calcServerSide.YEAR = value.substring(0, 4)
      }
    } else if (key === 'Fläche' || key === 'AREA') {
      if (value === '> 800 m²') {
        this.calcServerSide.AREA = '850'
      } else {
        this.calcServerSide.AREA = value.split(' ')[0] as string
      }
    }

    runInAction(() => {
      if (this.priceInfo.showPriceHousing === false && this.priceInfo.showPriceHeating === false) {
        this.priceInfo.showPrice = false
        this.priceInfo.alternativeText = this.appStore.wtSettings.pricePage.text.show_if_no_price_because_heating_and_housing
      } else if (
        this.priceInfo.showPriceHousing === true &&
        this.priceInfo.showPriceHeating === false
      ) {
        this.priceInfo.showPrice = false
        this.priceInfo.alternativeText = this.appStore.wtSettings.pricePage.text.show_if_no_price_because_heating
      } else if (
        this.priceInfo.showPriceHousing === false &&
        this.priceInfo.showPriceHeating === true
      ) {
        this.priceInfo.showPrice = false
        this.priceInfo.alternativeText = this.appStore.wtSettings.pricePage.text.show_if_no_price_because_housing
      } else {
        this.priceInfo.showPrice = true
      }
    })

    console.log('____ show price: ', this.priceInfo.showPrice)

    this.standardAnswers = toJS(answers)
    // console.log('_____ standard: ', toJS(this.standardAnswers))
    this.updateStandardAnswerNumber()
    if (this.standardAnswersNumber === 4) {
      this.appStore.calculateCost()
    }
  }

  updateBuildingType = (newData: any) => {
    this.buildingTypeOptions = toJS(newData)
  }

  updateBuildYear = (newDataIndex: number) => {
    this.buildYear = toJS(BuildingYearSteps[newDataIndex])
  }

  updateBuildingArea = (newDataIndex: number) => {
    this.buildingArea = toJS(BuildingAreaSteps[newDataIndex])
  }

  updateHeatingType = (newData: any) => {
    this.heatingTypes = toJS(newData)
  }

  setHeatingTypeHint = (heatingTypeHints: { [key: string]: any }, heatingTypeValue: string) => {
    const heatingTypeValueId = heatingTypeOptions.find(
      (option: SelectOptionType) => option.value === heatingTypeValue
    )?.value_id
    this.selectedHeatingTypeSetting = heatingTypeHints[heatingTypeValueId as string] as {
      procentualPriceExtra: number
      showPrice: boolean
      text: string
    }
    return this.selectedHeatingTypeSetting.text
  }

  updateGasConnection = (newData: any) => {
    this.gasConnectionTypes = toJS(newData)
  }

  updateStandardAnswerNumber = () => {
    const answers: StandardAnswersType[] = this.standardAnswers

    let i: number = 0
    answers.forEach((answer: StandardAnswersType) => {
      if (answer.name !== 'GAS_CONNECTION') {
        if (answer.value !== '') {
          i = i + 1
        }
      }
    })
    this.standardAnswersNumber = i
    this.allStandardQuestionAnswered = this.standardAnswersNumber >= 4
  }

  /* ============= additional questions part =============== */
  openAdditionalQuestions = () => {
    this.mapAdditionalQuestionActiveState()
    this.showAdditionalQuestions = true
    this.formatAdditionalAnswers()
  }

  updateAdditionalAnswerNumber = () => {
    const answers: AdditionalAnswerType[] = this.additionalAnswers
    let i: number = 0
    answers.forEach((item: AdditionalAnswerType) => {
      if (item.value.length !== 0) {
        i = i + 1
      }
    })
    this.additionalAnswerNumber = i
  }

  updateAdditionalAnswers = (key: string, value: string | string[]) => {
    const currentQuestion = toJS(this.appStore.questions).find(
      (question: any) => question.question === key
    )!
    const { type, selectSubType } = currentQuestion
    const { absolute, procentual } = calculateCostValue(
      type,
      selectSubType,
      toJS(this.appStore.questions),
      key,
      value
    )
    console.log(
      'test: ',
      calculateCostValue(type, selectSubType, toJS(this.appStore.questions), key, value)
    )

    const answers: AdditionalAnswerType[] = this.additionalAnswers
    const updatedAnswers = answers.map((item: AdditionalAnswerType) => {
      if (item.question === key) {
        item.cost!.absolute = absolute
        item.cost!.procentual = procentual
        return { ...item, value }
      }
      return item
    })
    console.log('_____ answer: ', toJS(updatedAnswers))
    this.additionalAnswers = toJS(updatedAnswers)
    this.updateAdditionalAnswerNumber()
    this.appStore.calculateCost()
  }

  /* ============ other actions / functions ============= */

  // data reformat
  mapAdditionalQuestionActiveState = () => {
    const questions = toJS(this.appStore.questions)
    const stateArray: { title: string; active: boolean }[] = []
    // console.log('start to map: ', questions)
    questions.map((item: any, index: number) => {
      const question: { title: string; active: boolean } = { title: '', active: false }
      question.title = item.question
      question.active = index === 0 ? true : false
      stateArray.push(question)
    })
    console.log('questions: ', stateArray)
    this.accordionActiveStatesForAdditional = toJS(stateArray)
  }

  formatAdditionalAnswers = () => {
    const questions = toJS(this.appStore.questions)
    const answerArray: AdditionalAnswerType[] = []

    questions.map((item: any) => {
      const answer: AdditionalAnswerType = {
        question: '',
        value: '',
        order: 0,
        id: '',
        cost: {
          absolute: 0,
          procentual: 0
        }
      }
      answer.question = item.question
      answer.value = ''
      answer.order = +item.order as number
      answer.id = item.id
      answerArray.push(answer)
    })
    this.additionalAnswers = toJS(answerArray)
  }

  // about UI
  openNextAccordion = (activeIndex: number) => {
    this.openIndex = activeIndex + 1
    // console.log('____________ active index 2: ', this.openIndex)

    if (!this.showAdditionalQuestions) {
      const updatedActiveStates = this.accordionActiveStatesForStandard
      updatedActiveStates.map((item: { active: boolean; title: string }, index: number) => {
        if (index === activeIndex + 1) {
          item.active = true
        } else {
          item.active = false
        }
      })
      this.accordionActiveStatesForStandard = toJS(updatedActiveStates)
    } else {
      const updatedActiveStates = toJS([
        ...this.accordionActiveStatesForStandard,
        ...this.accordionActiveStatesForAdditional
      ])
      const length = updatedActiveStates.length
      // console.log('____ show all : ', updatedActiveStates)
      updatedActiveStates.map((item: { active: boolean; title: string }, index: number) => {
        if (index === activeIndex + 1) {
          item.active = true
        } else {
          item.active = false
        }
      })
      this.accordionActiveStatesForStandard = toJS(updatedActiveStates).slice(0, 4)
      this.accordionActiveStatesForAdditional = toJS(updatedActiveStates).slice(4, length)
    }
    this.checkAllAccordionState()
  }

  // openNextAccordionMobile = (title: string, index?: number) => {
  //   const answers: { [key: string]: string } = this.standardAnswers
  //   if ((answers[title] = '')) {
  //     confirmAlert({
  //       customUI: ({ onClose }) => {
  //         return (
  //           <></>
  //         )
  //       }
  //     })
  //   }
  // }
  openOneAccordionAndCloseOthers = (activeIndex: number) => {
    this.openIndex = activeIndex
    // console.log('____________ active index: ', this.openIndex)

    if (!this.showAdditionalQuestions) {
      const updatedActiveStates = this.accordionActiveStatesForStandard
      updatedActiveStates.map((item: { active: boolean; title: string }, index: number) => {
        if (index === activeIndex) {
          item.active = true
        } else {
          item.active = false
        }
      })
      this.accordionActiveStatesForStandard = toJS(updatedActiveStates)
    } else {
      const updatedActiveStates = toJS([
        ...this.accordionActiveStatesForStandard,
        ...this.accordionActiveStatesForAdditional
      ])
      const length = updatedActiveStates.length
      updatedActiveStates.map((item: { active: boolean; title: string }, index: number) => {
        if (index === activeIndex) {
          item.active = true
        } else {
          item.active = false
        }
      })
      this.accordionActiveStatesForStandard = toJS(updatedActiveStates).slice(0, 4)
      this.accordionActiveStatesForAdditional = toJS(updatedActiveStates).slice(4, length)
    }
    this.checkAllAccordionState()
  }

  closeAllAccordions = () => {
    if (!this.showAdditionalQuestions) {
      const updatedActiveStates = this.accordionActiveStatesForStandard
      updatedActiveStates.map((item: { active: boolean; title: string }) => {
        item.active = false
      })
      this.accordionActiveStatesForStandard = toJS(updatedActiveStates)
    } else {
      const updatedActiveStates = toJS([
        ...this.accordionActiveStatesForStandard,
        ...this.accordionActiveStatesForAdditional
      ])
      const length = updatedActiveStates.length
      updatedActiveStates.map((item: { active: boolean; title: string }) => {
        item.active = false
      })
      this.accordionActiveStatesForStandard = toJS(updatedActiveStates).slice(0, 4)
      this.accordionActiveStatesForAdditional = toJS(updatedActiveStates).slice(4, length)
    }
    this.checkAllAccordionState()
  }

  checkAllAccordionState = () => {
    if (!!this.showAdditionalQuestions) {
      const updatedActiveStates = toJS([
        ...this.accordionActiveStatesForStandard,
        ...this.accordionActiveStatesForAdditional
      ])

      const found = updatedActiveStates.find(
        (item: { active: boolean; title: string }) => item.active === true
      )
      this.allAccordionsClosed = !found
    } else {
      this.allAccordionsClosed = false
    }
  }

  showResultMobile = (state: boolean) => {
    this.isResultMobileOpen = state
  }

  hideResultMobileAfterTriggered = (activeIndex: number) => {
    this.isResultMobileTriggered = true
    this.showResultMobile(false)
    this.openIndex = activeIndex
  }

  get showResultTable() {
    const shownPriceForSelectedBuidling = ['EFH', 'RH', 'WHG'].includes(
      this.calcServerSide.HEATING_TYPE
    )
    return (
      shownPriceForSelectedBuidling &&
      this.appStore?.wtSettings.pricePage.showComparison &&
      this.priceInfo.showPriceHeating &&
      this.priceInfo.showPriceHousing
    )
  }
}

export default QuestionStore
