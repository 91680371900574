import { readableColor } from 'polished'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from '../../../theme'
import { DeviceContext, DeviceType } from '../../../utils'
import { FlexContainer } from '../FlexContainer'
import { Space } from '../Space'
import { SVGIcon } from '../SVGs'
import { Typography } from '../Typography'

interface SelectItemProps extends SelectItemStyleProps {
  name?: string
  // withSvg?: boolean
  selected?: boolean
  svgName?: string
  value: string
  onSelect?(): void
  isMultiple?: boolean
}

interface SelectItemStyleProps {
  itemWidth?: string
  isInline?: boolean
}

export const SelectItem: React.FC<SelectItemProps> = ({
  name,
  // withSvg,
  selected,
  svgName,
  value,
  onSelect,
  itemWidth,
  isInline,
  isMultiple
}) => {
  const { device } = useContext(DeviceContext)
  const [isSelected, setIsSelected] = useState(selected)

  useEffect(() => {
    setIsSelected(selected)
  }, [selected])

  const handleChangeCheckState = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMultiple) {
      setIsSelected(event.target.checked)
    } else {
      setIsSelected(true)
    }
    if (onSelect) {
      onSelect()
    }
  }

  const handleClick = () => {
    setIsSelected(true)
    if (onSelect) {
      onSelect()
    }
  }

  return (
    <>
      {!!svgName ? (
        <FlexContainer
          onClick={handleClick}
          style={{ width: device === DeviceType.LAPTOP ? '150px' : '140px' }}
          className='option_with_icon'
          flexDirection='column'
          alignItems='center'>
          <Space
            mr={
              device === DeviceType.LAPTOP
                ? `${AppTheme.spacingSize.XL}px`
                : `${AppTheme.spacingSize.M}px`
            }
            mb={`${AppTheme.spacingSize.XL}px`}>
            <SelectItemContainer>
              {!!svgName && <SVGIcon name={svgName} active={selected || isSelected} />}
              <Space mt={AppTheme.spacingSize.M}>
                <Typography
                  color={AppTheme.colors.darkgray}
                  fontSize={`${AppTheme.fonts.sizes.size_Button}px`}>
                  {value}
                </Typography>
              </Space>
            </SelectItemContainer>
            <OptionInput
              type='checkbox'
              value={value}
              name={name}
              checked={selected || isSelected}
              onChange={handleChangeCheckState}
            />
          </Space>
        </FlexContainer>
      ) : (
        <TextOptionContainer
          onClick={handleClick}
          isInline={isInline}
          itemWidth={itemWidth}
          className='text_option'>
          <TextOption active={selected || isSelected}>{value}</TextOption>
          <OptionInput
            type='checkbox'
            value={value}
            name={name}
            checked={selected || isSelected}
            onChange={handleChangeCheckState}
          />
        </TextOptionContainer>
      )}
    </>
  )
}

SelectItem.defaultProps = {
  isInline: true
}

interface OptionStyleProps {
  active?: boolean
}

const SelectItemContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  cursor: pointer;
`

const TextOptionContainer = styled.div<SelectItemStyleProps>`
  display: ${(props) => (props.isInline ? 'inline-block' : 'block')};
  width: ${(props) => props.itemWidth && props.itemWidth};
  cursor: pointer;
  margin-right: 12px;
  margin-bottom: 12px;
`

const TextOption = styled.label<OptionStyleProps>`
  display: inline-block;
  border: ${(props) =>
    !props.active ? '1px solid rgb(178, 178, 178)' : `1px solid ${props.theme.colors.secondary}`};
  padding: 10px 16px;
  box-sizing: border-box;
  width: 100%;
  color: ${(props) =>
    !props.active
      ? ' rgb(178, 178, 178)'
      : readableColor(AppTheme.colors.secondary, AppTheme.colors.darkgray, AppTheme.colors.white)};
  font-size: ${(props) => props.theme.fonts.sizes.size_Form};
  font-family: ${(props) => props.theme.fonts.family};
  background-color: ${(props) => (props.active ? props.theme.colors.secondary : 'transparent')};
  cursor: pointer;
  transition: all 0.5s ease;
  user-select: none;
`

const OptionInput = styled.input`
  display: none;
`

export default SelectItem
