/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactDOM from 'react-dom'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { StoreContextProvider, DeviceContextProvider, AppStoreContext } from './utils'
import { HomeContainer as Home } from './components'
import { AppTheme, GlobalStyles } from './theme'

const App: React.FC = ({}) => {
  const store = useContext(AppStoreContext)
  const [isLoading, setIsLoading] = useState(true)
  const [inProp, setInProp] = useState(false)
  const [appTitle, setAppTitle] = useState('Loading...')

  useEffect(() => {
    store!.loadWTSettings().then((wtSettings: any) => {
      setAppTitle(wtSettings.basics.text.heatToolName)
      setIsLoading(false)
      setInProp(true)
    })
  }, [isLoading, appTitle, store])

  return (
    <DeviceContextProvider>
      <ThemeProvider theme={AppTheme}>
        <StoreContextProvider>
          {!isLoading && (
            <React.Fragment>
              <CSSTransition in={inProp} timeout={600} classNames='wc-app'>
                <Home appTitle={appTitle} />
              </CSSTransition>
              <GlobalStyles />
            </React.Fragment>
          )}
        </StoreContextProvider>
      </ThemeProvider>
    </DeviceContextProvider>
  )
}

export default App

function renderApp(rootEl: HTMLElement) {
  ReactDOM.render(<App />, rootEl)
}

function renderStandaloneApp(rootEl: HTMLElement) {
  ReactDOM.render(<App />, rootEl)
}

console.log('Test in app.tsx')

const methodName = `render_app_wt`
// this one we need for integrated app start
;(window as any)[methodName] = renderApp
// this one we need for standalone app start
;(window as any).renderWTApp = renderStandaloneApp
