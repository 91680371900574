import { DefaultTheme } from 'styled-components'

export const AppTheme: DefaultTheme = {
  colors: {
    primary: '#FFE000',
    secondary: '#A0C4C9',
    white: '#FFF',
    black: '#000',
    lightgray: '#EEE',
    gray: '#B9B9B9',
    darkgray: '#656565',
    stronggray: '#212121',
    red: '#E20000'
  },
  spacingSize: {
    XS: 8,
    S: 12,
    M: 16,
    L: 24,
    XL: 32
  },
  window: {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth
  },
  fonts: {
    family: "'Roboto',sans-serif",
    sizes: {
      size_Form: 16,
      size_Paragraph_S: 12,
      size_Paragraph_L: 14,
      size_Paragraph: 13,
      size_Button: 14,
      size_H2: 18
    },
    lineHeights: {
      lineHeight_Form: 20,
      lineHeight_Paragraph: 18,
      lineHeight_H2: 18
    },
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700
    }
  }
}

export default AppTheme
