/* eslint-disable react/display-name */
import { observer } from 'mobx-react-lite'
import React, { ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import Collapse from '@kunukn/react-collapse'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import styled from 'styled-components'
import { AppTheme } from '../../../theme'
import { Divider, FlexContainer, Space, SVGIcon, SVGNames, Typography } from '../../atoms'
import { AppStoreContext } from '../../../utils'
import { Alert } from '../Alert'

interface AccordionMobileProps {
  isOpen: boolean
  title: string
  index?: number
  description: string
  children?: ReactElement | ReactNode
  noteUnderTitle?: string
  onHide?(index?: number): void
  onOpen?(index?: number): void
  showContinueBtn?: boolean
  showResultBtn?: boolean
  isLast?: boolean
  isContinueBtnActive?: boolean
}

export const AccordionMobile: React.FC<AccordionMobileProps> = observer(
  ({
    isOpen,
    title,
    description,
    noteUnderTitle,
    onHide,
    children,
    showContinueBtn,
    showResultBtn,
    index,
    onOpen,
    isLast,
    isContinueBtnActive
  }) => {
    const [expand, setExpand] = useState(isOpen)
    const store = useContext(AppStoreContext)
    const questionStore = store!.questionStore

    useEffect(() => {
      setExpand(isOpen)
    }, [index, isOpen])

    const handleClose = () => {
      if (isLast) {
        console.log('is last')
        // if index is the last
        if (isContinueBtnActive) {
          // if it belongs to additional question
          console.log('show result')
          questionStore.showResultMobile(true)
        } else {
          console.log('something else')
          if (!noteUnderTitle) {
            // if is answered
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <Alert
                    onClose={onClose}
                    title='Bitte beantworten Sie alle 4 Fragen'
                    description='Nach der Beantwortung kommen Sie zur Preisvorschau bzw. zum Angebotsformular.'
                  />
                )
              }
            })
          } else if (!questionStore.allStandardQuestionAnswered) {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <Alert
                    onClose={onClose}
                    title='Bitte beantworten Sie alle 4 Fragen'
                    description='Nach der Beantwortung kommen Sie zur Preisvorschau bzw. zum Angebotsformular.'
                  />
                )
              }
            })
          } else {
            if (onHide) {
              onHide()
            }
          }
        }
      } else if (!noteUnderTitle || noteUnderTitle === '') {
        if (!isContinueBtnActive) {
          confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <Alert
                  onClose={onClose}
                  title='Bitte beantworten Sie die Frage'
                  description='Dann können Sie hier weiter klicken!'
                />
              )
            }
          })
        } else {
          setExpand(false)
          if (onHide && (index !== null || index !== undefined)) {
            onHide(index as number)
          }
        }
      } else {
        setExpand(false)
        if (onHide && (index !== null || index !== undefined)) {
          onHide(index as number)
        }
      }
    }

    const handleOpen = () => {
      setExpand(!expand)
      if (onOpen && (index !== null || index !== undefined)) {
        if (expand) {
          onOpen(-1)
        } else {
          onOpen(index)
        }
      }
    }

    return (
      <AccordionContainer className='accordion_mobile' expanded={expand}>
        <Space mt={AppTheme.spacingSize.S}>
          <TitleContainer onClick={handleOpen}>
            <div>
              <Typography fontSize={`${AppTheme.fonts.sizes.size_H2}px`}>{title}</Typography>
              {!!noteUnderTitle && (
                <Typography
                  position='absolute'
                  fontSize={`${AppTheme.fonts.sizes.size_Paragraph}px`}
                  color={AppTheme.colors.primary}>
                  {noteUnderTitle}
                </Typography>
              )}
            </div>
            <SVGIcon name={SVGNames.IconEdit} active={!!noteUnderTitle && noteUnderTitle !== ''} />
          </TitleContainer>
          <Space mt={AppTheme.spacingSize.S} height='auto'>
            <Divider />
          </Space>

          <Collapse isOpen={expand} overflowOnExpanded={true}>
            <CollapsibleContainer
              height={AppTheme.window.innerHeight - 151 - 30}
              className='collapsible-container'>
              <Space
                pt={`${AppTheme.spacingSize.M}px`}
                pb={`${AppTheme.spacingSize.XL}px`}
                height='auto'>
                <Typography fontSize={`${AppTheme.fonts.sizes.size_Form}px`}>
                  {description}
                </Typography>
                <Space mt={`${AppTheme.spacingSize.XL}px`} height='auto'>
                  {children}
                  {/* <Select
                  isMultiple={false}
                  selectedOptions={selectedIcon}
                  setSelectedOptions={setSelectedIcon}
                /> */}
                </Space>
              </Space>

              {!!showContinueBtn && (
                <FlexContainer
                  flexDirection='row'
                  justifyContent={showResultBtn ? 'space-between' : 'flex-end'}>
                  <>
                    {!!showResultBtn && (
                      <IconContainer
                        className='icon_container'
                        bgColor={AppTheme.colors.secondary}
                        onClick={() => questionStore.showResultMobile(true)}>
                        <SVGIcon name={SVGNames.IconShowResult} className={'icon_show_result'} />
                      </IconContainer>
                    )}
                  </>
                  <IconContainer
                    className='icon_container'
                    bgColor={
                      isContinueBtnActive
                        ? AppTheme.colors.secondary
                        : !!noteUnderTitle
                        ? AppTheme.colors.secondary
                        : AppTheme.colors.gray
                    }
                    onClick={handleClose}>
                    <SVGIcon name={SVGNames.IconGoOn} className={'icon_next'} />
                  </IconContainer>
                </FlexContainer>
              )}
            </CollapsibleContainer>
          </Collapse>
        </Space>
      </AccordionContainer>
    )
  }
)

AccordionMobile.defaultProps = {
  showContinueBtn: true,
  isLast: false
}

interface CollapseProps {
  expanded?: boolean
  height?: number
}

const AccordionContainer = styled.div<CollapseProps>`
  width: 100%;
  /* height: ${(props) => (props.expanded ? 'calc(100% - 10px)' : 'auto')};
  max-height: ${(props) => (props.expanded ? 'calc(100% - 10px)' : 'auto')}; */
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const CollapsibleContainer = styled.div<CollapseProps>`
  width: 100%;
  height: ${(props) => props.height}px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  &::-webkit-scrollbar {
    display: none;
  }
`

interface IconContainerProps {
  bgColor: string
}
export const IconContainer = styled.div<IconContainerProps>`
  cursor: inherit;
  overflow: hidden;
  background-color: ${(props) => props.bgColor};
  display: block;
  width: 54px;
  height: 54px;
  position: relative;
  float: right;
`
export default AccordionMobile
