import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { AppTheme, Displays } from '../../../theme'
import { AppStoreContext, DeviceContext, DeviceType, getUploadURL } from '../../../utils'
import { Space, SVGIcon, SVGNames, Typography } from '../../atoms'

export const EmailFeedback: React.FC<{ succeeded: boolean }> = ({ succeeded }) => {
  const store = useContext(AppStoreContext)
  const phone = store.ciSettings?.settings_sw_text_sw_phone
  const email = store.ciSettings?.settings_sw_text_sw_email
  const { device } = useContext(DeviceContext)
  const isMobile = device === DeviceType.MOBILE
  const [feedbackText, setFeedbackText] = useState('')

  useEffect(() => {
    if (!!store) {
      setFeedbackText(
        !!store.wtSettings.form.text.sendSuccess
          ? store.wtSettings.form.text.sendSuccess
          : 'Ihre Daten wurden erfolgreich an uns übermittelt.'
      )
    }
  }, [getUploadURL, store])

  return (
    <FeedbackContainer>
      <Space px={AppTheme.spacingSize.L} height='auto'>
        {succeeded ? (
          <TextContainer>
            <Typography
              fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
              display={Displays.BLOCK}
              lineHeight='3rem'>
              {'Vielen Dank!'}
            </Typography>
            <Typography
              lineHeight='1.5rem'
              fontSize={`${AppTheme.fonts.sizes.size_Paragraph_L}px`}
              display={Displays.BLOCK}>
              {feedbackText}
            </Typography>
          </TextContainer>
        ) : (
          <TextContainer>
            <Space mt={`${AppTheme.spacingSize.L}px`} mb={`${AppTheme.spacingSize.XS}px`}>
              <Typography
                fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
                color={AppTheme.colors.red}>
                {'Oops, etwas ist schief gelaufen.'}
              </Typography>
            </Space>
            <Typography
              color={AppTheme.colors.darkgray}
              fontSize={`${AppTheme.fonts.sizes.size_Paragraph_L}px`}
              lineHeight='1.5rem'
              display={Displays.BLOCK}>
              {`Bitte versuchen Sie es nochmal oder kontaktieren Sie uns unter ${phone} oder ${email}. \nVielen Dank!`}
            </Typography>
          </TextContainer>
        )}
      </Space>
      <SVGContainer width={isMobile ? '100vw' : '35vw'}>
        <SVGIcon name={SVGNames.House} />
      </SVGContainer>
    </FeedbackContainer>
  )
}

const FeedbackContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.colors.darkgray};
`

const TextContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  color: ${(props) => props.theme.colors.darkgray};
`

const SVGContainer = styled.div<{ width: string }>`
  position: absolute;
  width: ${(props) => props.width};
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

export default EmailFeedback
