import { readableColor } from 'polished'
import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { AppTheme, Displays } from '../../../theme'
import { Space } from '../Space'

interface TypographyProps extends TypographyStyleProps {
  className?: string
  children: string | ReactElement | (string | ReactElement)[]
}

interface TypographyStyleProps {
  color?: string
  display?: Displays.BLOCK | Displays.INLINE_BLOCK | Displays.INLINE
  fontFamily?: string
  fontWeight?: string
  fontSize?: string
  textAlignment?: 'left' | 'center' | 'right' | 'justify'
  lineHeight?: string
  letterSpacing?: string
  textTransform?: string
  wordWrap?: boolean
  width?: string
  fontStyle?: string
  withBackgroundColor?: boolean
  position?: string
}

export const Typography: React.FC<TypographyProps> = ({
  children,
  withBackgroundColor,
  ...props
}) => {
  return withBackgroundColor ? (
    <ParagraphContainer>
      <Space px={`${AppTheme.spacingSize.L}px`} py={`${AppTheme.spacingSize.M}px`}>
        <StyledText
          {...props}
          color={readableColor(
            AppTheme.colors.secondary,
            AppTheme.colors.black,
            AppTheme.colors.white
          )}>
          {children}
        </StyledText>
      </Space>
    </ParagraphContainer>
  ) : (
    <StyledText {...props}>{children}</StyledText>
  )
}

Typography.defaultProps = {
  letterSpacing: 'normal',
  withBackgroundColor: false
}

const StyledText = styled.div<TypographyStyleProps>`
  color: ${(props) => props.color};
  display: ${(props) => props.display};
  font-family: ${(props) => props.fontFamily};
  font-size: ${(props) => props.fontSize};
  text-align: ${(props) => props.textAlignment};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(props) => props.letterSpacing};
  text-transform: ${(props) => props.textTransform};
  white-space: ${(props) => (props.wordWrap ? 'nowrap' : 'break-spaces')};
  width: ${(props) => (props.width ? props.width : '100%')};
  font-weight: ${(props) => props.fontWeight};
  font-style: ${(props) => props.fontStyle};
  position: ${(props) => props.position};
`

const ParagraphContainer = styled.div`
  background-color: ${(props) => props.theme.colors.secondary};
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  border-radius: 5px;
  /* margin: 8px 0px; */
`

export default Typography
