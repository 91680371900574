import React, { useContext } from 'react'
import NumberFormat from 'react-number-format'
import { readableColor } from 'polished'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { AppStoreContext } from '../../../utils'
import { AppTheme } from '../../../theme'
import { Space, Typography } from '../../atoms'

export const Comparison: React.FC = observer(() => {
  const store = useContext(AppStoreContext)

  const fontColor = readableColor(
    AppTheme.colors.secondary,
    AppTheme.colors.stronggray,
    AppTheme.colors.white
  )

  const getPrice = (what: string) => {
    if (what === 'serviceYear') {
      return store!.pricePerMonthForPreview * 12
    } else if (what === 'service10Y') {
      return store!.pricePerMonthForPreview * 12 * 10
    } else if (what === 'buyYear') {
      return store!.pricePerMonthBuyForPreview * 12
    } else if (what === 'buy10Y') {
      return store!.pricePerMonthBuyForPreview * 12 * 10
    } else if (what === 'buyMonth') {
      return store!.pricePerMonthBuyForPreview
    } else if (what === 'serviceMonth') {
      return store!.pricePerMonthForPreview
    }
  }

  return (
    <StyledCompTable>
      <StyledCompRow>
        <StyledCompCol1 />
        <StyledCompCol2 paddingBig>
          <Space py={AppTheme.spacingSize.L}>Kauf einer Heizung</Space>
        </StyledCompCol2>
        <StyledCompCol3 paddingBig>
          <Space py={AppTheme.spacingSize.L}>
            <Typography color={fontColor}>{store!.wtSettings.basics.text.heatToolName}</Typography>
          </Space>
        </StyledCompCol3>
      </StyledCompRow>

      <StyledCompRow>
        <StyledCompCol1>
          Finanzierungskosten bspw. 10 Jahre
          <br />
          + Wartung
          <br />
          + Reparatur
          <br />+ Schornsteinfeger
          {/* <br />+ Brennstoff für 10 Jahre */}
        </StyledCompCol1>
        <StyledCompCol2 bold>
          <NumberFormat
            thousandSeparator='.'
            decimalSeparator=','
            displayType='text'
            value={getPrice('buyMonth')}
          />
          {' € / Monat'}
        </StyledCompCol2>
        <StyledCompCol3 bold>
          <Typography color={fontColor}>
            <NumberFormat
              thousandSeparator='.'
              decimalSeparator=','
              displayType='text'
              value={getPrice('serviceMonth')}
            />
            {' € / Monat'}
          </Typography>
        </StyledCompCol3>
      </StyledCompRow>

      <StyledCompRow>
        <StyledCompCol1>Kostenvergleich der jährlichen Belastung</StyledCompCol1>
        <StyledCompCol2 bold>
          <Space py={AppTheme.spacingSize.M}>
            <NumberFormat
              thousandSeparator='.'
              decimalSeparator=','
              displayType='text'
              value={getPrice('buyYear')}
            />
            {' € / Jahr'}
          </Space>
        </StyledCompCol2>
        <StyledCompCol3 bold>
          <Space py={AppTheme.spacingSize.M}>
            <Typography color={fontColor}>
              <NumberFormat
                color={fontColor}
                thousandSeparator='.'
                decimalSeparator=','
                displayType='text'
                value={getPrice('serviceYear')}
              />
              {' € / Jahr'}
            </Typography>
          </Space>
        </StyledCompCol3>
      </StyledCompRow>
    </StyledCompTable>
  )
})

interface TableStyleProps {
  paddingBig?: boolean
  bold?: boolean
}

export const StyledCompTable = styled.div`
  display: table;
  width: 100%;
`

export const StyledCompRow = styled.div`
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
  word-wrap: break-word !important;
`

export const StyledCompCol1 = styled.div`
  width: 50%;
  border-top: 1px solid ${(props) => props.theme.colors.darkgray};
  box-sizing: border-box;
  padding-right: 10px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.darkgray};
  line-height: 16px;
`

export const StyledCompCol2 = styled.div<TableStyleProps>`
  text-align: center;
  width: 25%;
  border-top: 1px solid ${(props) => props.theme.colors.darkgray};
  padding-right: 10px;
  box-sizing: border-box;
  padding-left: 10px;
  padding-top: ${(props) => (props.paddingBig ? '12px' : '7px')};
  padding-bottom: ${(props) => (props.paddingBig ? '12px' : '7px')};
  font-size: 18px;
  font-weight: ${(props) => (props.bold ? props.theme.fonts.weights.regular : 'normal')};

  color: ${(props) => props.theme.colors.darkgray};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

export const StyledCompCol3 = styled.div<TableStyleProps>`
  text-align: center;
  width: 25%;
  background-color: ${(props) => props.theme.colors.secondary};
  border-top: 1px solid #fff;
  box-sizing: border-box;
  padding-right: 10px;
  padding-left: 10px;
  padding-top: ${(props) => (props.paddingBig ? '12px' : '7px')};
  padding-bottom: ${(props) => (props.paddingBig ? '12px' : '7px')};
  font-size: 18px;
  font-weight: ${(props) => (props.bold ? props.theme.fonts.weights.regular : 'normal')};

  color: ${(props) => props.theme.colors.stronggray};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

Comparison.displayName = 'Comparison'

export default Comparison
