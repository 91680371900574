import { makeAutoObservable, toJS } from 'mobx'

class UIStore {
  isContactMobileVisible: boolean = false
  emailState: 'succeeded' | 'failed' | '' = ''
  showContact: boolean = false
  leftSideWidth: 65 | 35 = 65

  public constructor() {
    makeAutoObservable(this)
    this.emailState = ''
  }

  triggerContactMobile = (state: boolean) => {
    this.isContactMobileVisible = state
    console.log(
      '_______ isContactOpen: ',
      this.isContactMobileVisible,
      toJS(!this.isContactMobileVisible)
    )
  }

  updateShowContact = (state: boolean) => {
    this.showContact = state
  }

  updateLeftSideWidth = (width: 65 | 35) => {
    this.leftSideWidth = width
  }

  updateEmailState = (state: 'succeeded' | 'failed' | '') => {
    this.emailState = state
  }
}

export default UIStore
