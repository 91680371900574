import React, { useContext } from 'react'
import styled from 'styled-components'
import { Button, Card, Space, Typography } from '../../atoms'
import { readableColor } from 'polished'
import { AppTheme } from '../../../theme'
import { observer } from 'mobx-react-lite'
import { AppStoreContext } from '../../../utils'
import { Contact, EmailFeedback, QuestionsMobile, ResultMobile } from '../../template'
import { LinkFooter } from '../../molecules'

interface HomeContentMobileProps {
  appTitle: string
}
export const HomeContentMoible: React.FC<HomeContentMobileProps> = observer(({ appTitle }) => {
  const store = useContext(AppStoreContext)
  const questionStore = store!.questionStore

  const handleBackToQuestionFromContact = () => {
    store!.uiStore.triggerContactMobile(false)
    store!.questionStore.showResultMobile(false)
    store!.questionStore.hideResultMobileAfterTriggered(-1)
  }

  return (
    <>
      <Container className='result_mobile'>
        <TitleContainer>
          {store!.uiStore.isContactMobileVisible && (
            <BackBtnContainer>
              <Button
                hasBorder={false}
                color={AppTheme.colors.white}
                fontSize={24}
                onClick={handleBackToQuestionFromContact}>
                ←
              </Button>
            </BackBtnContainer>
          )}
          <Typography
            color={readableColor(
              AppTheme.colors.primary,
              AppTheme.colors.stronggray,
              AppTheme.colors.white
            )}>
            {appTitle}
          </Typography>
        </TitleContainer>
        <Card height={'calc(100% - 44px)'}>
          <Space
            px={`${AppTheme.spacingSize.M}px`}
            py={`${AppTheme.spacingSize.M}px`}
            overflow='scroll'>
            <QuestionsMobile />
          </Space>
        </Card>
        {store!.uiStore.isContactMobileVisible ? (
          <ContactContainer className='contact_mobile'>
            <Card height={'calc(100% - 44px)'}>
              <Space
                px={`${AppTheme.spacingSize.L}px`}
                py={`${AppTheme.spacingSize.M}px`}
                overflow='scroll'>
                {store.uiStore.emailState === '' ? (
                  <Contact />
                ) : (
                  <EmailFeedback succeeded={store.uiStore.emailState === 'succeeded'} />
                )}
              </Space>
            </Card>
          </ContactContainer>
        ) : (
          <ResultMobile
            hidden={!questionStore.isResultMobileOpen}
            onHide={questionStore.showResultMobile}
          />
        )}
      </Container>
      <FooterContainer>
        <LinkFooter />
      </FooterContainer>
    </>
  )
})

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  height: ${(props) => props.theme.window.innerHeight - 30}px;
  width: 100%;
  margin: 0;
  padding: 0;
`
const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  text-align: center;
`

const ContactContainer = styled.div`
  position: absolute;
  top: 44px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
`

const BackBtnContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  left: 10px;
  top: 0;
`

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0px;
  height: 30px;
  width: 100%;
  z-index: 100000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 16px;
  border-top: 1px dashed #e1e1e1;
  background-color: white;
`
export default HomeContentMoible
