import { SelectOptionType } from '../components/atoms'
import { WTSettings } from './static'

export const mapResultsToWTSettings = (results: any) => {
  const wtSettings = WTSettings

  if (results.settings.settings_sw_boolean_with_tww) {
    wtSettings.calculationServerParams.withTww =
      results.settings.settings_sw_boolean_with_tww !== 'true' ? false : true
  } else {
    wtSettings.calculationServerParams.withTww = false
  }

  if (
    results.settings.settings_sw_text_buy_cost_factor &&
    results.settings.settings_sw_text_buy_cost_factor.question &&
    results.settings.settings_sw_text_buy_cost_factor.question !== ''
  ) {
    wtSettings.calculationServerParams.buyCostFactor = parseFloat(
      results.settings.settings_sw_text_buy_cost_factor.question
    )
  }

  if (
    results.settings.settings_sw_text_extra_cost_monthly_with_tww &&
    results.settings.settings_sw_text_extra_cost_monthly_with_tww.question &&
    results.settings.settings_sw_text_extra_cost_monthly_with_tww.question !== ''
  ) {
    wtSettings.calculationServerParams.extraCostMonthlyWithTww = parseFloat(
      results.settings.settings_sw_text_extra_cost_monthly_with_tww.question
    )
  }

  if (
    results.settings.settings_sw_text_extra_cost_monthly_without_tww &&
    results.settings.settings_sw_text_extra_cost_monthly_without_tww.question &&
    results.settings.settings_sw_text_extra_cost_monthly_without_tww.question !== ''
  ) {
    wtSettings.calculationServerParams.extraCostMonthlyWithoutTww = parseFloat(
      results.settings.settings_sw_text_extra_cost_monthly_without_tww.question
    )
  }

  if (
    results.settings.settings_sw_boolean_surcharge_with_tww ||
    results.settings.settings_sw_boolean_surcharge_with_tww === 'true'
  ) {
    wtSettings.calculationServerParams.calcSurchargeWithTww = true
  } else {
    wtSettings.calculationServerParams.calcSurchargeWithTww = false
  }

  if (
    results.settings.settings_sw_boolean_surcharge_without_tww ||
    results.settings.settings_sw_boolean_surcharge_without_tww === 'true'
  ) {
    wtSettings.calculationServerParams.calcSurchargeWithoutTww = true
  } else {
    wtSettings.calculationServerParams.calcSurchargeWithoutTww = false
  }

  if (
    results.settings.settings_sw_boolean_price_efh_rh ||
    results.settings.settings_sw_boolean_price_efh_rh === 'true'
  ) {
    wtSettings.showPriceForHousing.EFH = true
    wtSettings.showPriceForHousing.RH = true
  } else {
    wtSettings.showPriceForHousing.EFH = false
    wtSettings.showPriceForHousing.RH = false
  }

  if (
    results.settings.settings_sw_boolean_price_mfh ||
    results.settings.settings_sw_boolean_price_mfh === 'true'
  ) {
    wtSettings.showPriceForHousing.MFH = true
  } else {
    wtSettings.showPriceForHousing.MFH = false
  }

  if (
    results.settings.settings_sw_boolean_price_whg ||
    results.settings.settings_sw_boolean_price_whg === 'true'
  ) {
    wtSettings.showPriceForHousing.WHG = true
  } else {
    wtSettings.showPriceForHousing.WHG = false
  }
  // info: serverside var names factor and text are not what they seem to be

  if (
    results.settings.settings_sw_boolean_price_gas ||
    results.settings.settings_sw_boolean_price_gas === 'true'
  ) {
    wtSettings.heatingType.GAS.showPrice = true
  } else {
    wtSettings.heatingType.GAS.showPrice = false
  }

  // if (
  //   results.settings.settings_sw_text_price_gas_cost_factor &&
  //   results.settings.settings_sw_text_price_gas_cost_factor.question &&
  //   results.settings.settings_sw_text_price_gas_cost_factor.question !== ''
  // ) {
  //   wtSettings.heatingType.GAS.procentualPriceExtra = parseFloat(
  //     results.settings.settings_sw_text_price_gas_cost_factor.question
  //   )
  // }

  if (
    results.settings.settings_sw_boolean_price_oil ||
    results.settings.settings_sw_boolean_price_oil === 'true'
  ) {
    wtSettings.heatingType.OIL.showPrice = true
  } else {
    wtSettings.heatingType.OIL.showPrice = false
  }

  // if (
  //   results.settings.settings_sw_text_price_oil_cost_factor &&
  //   results.settings.settings_sw_text_price_oil_cost_factor.question &&
  //   results.settings.settings_sw_text_price_oil_cost_factor.question !== ''
  // ) {
  //   wtSettings.heatingType.OIL.procentualPriceExtra = parseFloat(
  //     results.settings.settings_sw_text_price_oil_cost_factor.question
  //   )
  // }

  if (
    results.settings.settings_sw_boolean_price_hp ||
    results.settings.settings_sw_boolean_price_hp === 'true'
  ) {
    wtSettings.heatingType.HP.showPrice = true
  } else {
    wtSettings.heatingType.HP.showPrice = false
  }

  // if (
  //   results.settings.settings_sw_text_price_hp_cost_factor &&
  //   results.settings.settings_sw_text_price_hp_cost_factor.question &&
  //   results.settings.settings_sw_text_price_hp_cost_factor.question !== ''
  // ) {
  //   wtSettings.heatingType.HP.procentualPriceExtra = parseFloat(
  //     results.settings.settings_sw_text_price_hp_cost_factor.question
  //   )
  // }

  if (
    results.settings.settings_sw_boolean_price_esh ||
    results.settings.settings_sw_boolean_price_esh === 'true'
  ) {
    wtSettings.heatingType.ESH.showPrice = true
  } else {
    wtSettings.heatingType.ESH.showPrice = false
  }

  // if (
  //   results.settings.settings_sw_text_price_esh_cost_factor &&
  //   results.settings.settings_sw_text_price_esh_cost_factor.question &&
  //   results.settings.settings_sw_text_price_esh_cost_factor.question !== ''
  // ) {
  //   wtSettings.heatingType.ESH.procentualPriceExtra = parseFloat(
  //     results.settings.settings_sw_text_price_esh_cost_factor.question
  //   )
  // }

  if (
    results.settings.settings_sw_boolean_price_wd ||
    results.settings.settings_sw_boolean_price_wd === 'true'
  ) {
    wtSettings.heatingType.WD.showPrice = true
  } else {
    wtSettings.heatingType.WD.showPrice = false
  }

  // if (
  //   results.settings.settings_sw_text_price_wd_cost_factor &&
  //   results.settings.settings_sw_text_price_wd_cost_factor.question &&
  //   results.settings.settings_sw_text_price_wd_cost_factor.question !== ''
  // ) {
  //   wtSettings.heatingType.WD.procentualPriceExtra = parseFloat(
  //     results.settings.settings_sw_text_price_wd_cost_factor.question
  //   )
  // }

  if (
    results.settings.settings_sw_boolean_price_ldh ||
    results.settings.settings_sw_boolean_price_ldh === 'true'
  ) {
    wtSettings.heatingType.LDH.showPrice = true
  } else {
    wtSettings.heatingType.LDH.showPrice = false
  }

  // if (
  //   results.settings.settings_sw_text_price_ldh_cost_factor &&
  //   results.settings.settings_sw_text_price_ldh_cost_factor.question &&
  //   results.settings.settings_sw_text_price_ldh_cost_factor.question !== ''
  // ) {
  //   wtSettings.heatingType.LDH.procentualPriceExtra = parseFloat(
  //     results.settings.settings_sw_text_price_ldh_cost_factor.question
  //   )
  // }

  if (
    results.settings.settings_sw_text_contracting_benefits &&
    results.settings.settings_sw_text_contracting_benefits.question &&
    results.settings.settings_sw_text_contracting_benefits.question !== ''
  ) {
    wtSettings.pricePage.text.contractingAdvantages =
      results.settings.settings_sw_text_contracting_benefits.question
  }

  if (
    results.settings.settings_sw_text_show_if_no_price_because_housing &&
    results.settings.settings_sw_text_show_if_no_price_because_housing.question &&
    results.settings.settings_sw_text_show_if_no_price_because_housing.question !== ''
  ) {
    wtSettings.pricePage.text.show_if_no_price_because_housing =
      results.settings.settings_sw_text_show_if_no_price_because_housing.question
  }

  if (
    results.settings.settings_sw_text_show_if_exotic_params &&
    results.settings.settings_sw_text_show_if_exotic_params &&
    results.settings.settings_sw_text_show_if_exotic_params !== ''
  ) {
    wtSettings.pricePage.text.show_if_exotic_params =
      results.settings.settings_sw_text_show_if_exotic_params.question
  }

  if (
    results.settings.settings_sw_text_show_if_no_price_because_heating &&
    results.settings.settings_sw_text_show_if_no_price_because_heating &&
    results.settings.settings_sw_text_show_if_no_price_because_heating !== ''
  ) {
    wtSettings.pricePage.text.show_if_no_price_because_heating =
      results.settings.settings_sw_text_show_if_no_price_because_heating.question
  }

  if (
    results.settings.settings_sw_text_show_if_no_price_because_heating_and_housing &&
    results.settings.settings_sw_text_show_if_no_price_because_heating_and_housing &&
    results.settings.settings_sw_text_show_if_no_price_because_heating_and_housing !== ''
  ) {
    wtSettings.pricePage.text.show_if_no_price_because_heating_and_housing =
      results.settings.settings_sw_text_show_if_no_price_because_heating_and_housing.question
  }

  if (
    results.settings.settings_sw_text_show_if_no_price &&
    results.settings.settings_sw_text_show_if_no_price &&
    results.settings.settings_sw_text_show_if_no_price !== ''
  ) {
    wtSettings.pricePage.text.show_if_no_price =
      results.settings.settings_sw_text_show_if_no_price.question
  }

  if (
    results.settings.settings_sw_text_show_if_price &&
    results.settings.settings_sw_text_show_if_price &&
    results.settings.settings_sw_text_show_if_price !== ''
  ) {
    wtSettings.pricePage.text.show_if_price =
      results.settings.settings_sw_text_show_if_price.question
  }

  if (
    results.settings.settings_sw_text_heat_tool_claim &&
    results.settings.settings_sw_text_heat_tool_claim !== ''
  ) {
    wtSettings.pricePage.text.textUnderLogo =
      results.settings.settings_sw_text_heat_tool_claim.question
  }

  if (
    results.settings.settings_sw_text_show_before_price &&
    results.settings.settings_sw_text_show_before_price &&
    results.settings.settings_sw_text_show_before_price !== ''
  ) {
    wtSettings.pricePage.text.show_before_price =
      results.settings.settings_sw_text_show_before_price.question
  }

  if (
    results.settings.settings_sw_text_show_after_price &&
    results.settings.settings_sw_text_show_after_price &&
    results.settings.settings_sw_text_show_after_price !== ''
  ) {
    wtSettings.pricePage.text.show_after_price =
      results.settings.settings_sw_text_show_after_price.question
  }

  if (
    results.settings.settings_sw_text_caption_ldh &&
    results.settings.settings_sw_text_caption_ldh.question &&
    results.settings.settings_sw_text_caption_ldh.question !== ''
  ) {
    wtSettings.heatingType.LDH.text = results.settings.settings_sw_text_caption_ldh.question
  }

  if (
    results.settings.settings_sw_text_caption_gas &&
    results.settings.settings_sw_text_caption_gas.question &&
    results.settings.settings_sw_text_caption_gas.question !== ''
  ) {
    wtSettings.heatingType.GAS.text = results.settings.settings_sw_text_caption_gas.question
  }

  if (
    results.settings.settings_sw_text_caption_esh &&
    results.settings.settings_sw_text_caption_esh.question &&
    results.settings.settings_sw_text_caption_esh.question !== ''
  ) {
    wtSettings.heatingType.ESH.text = results.settings.settings_sw_text_caption_esh.question
  }

  if (
    results.settings.settings_sw_text_caption_wd &&
    results.settings.settings_sw_text_caption_wd.question &&
    results.settings.settings_sw_text_caption_wd.question !== ''
  ) {
    wtSettings.heatingType.WD.text = results.settings.settings_sw_text_caption_wd.question
  }

  if (
    results.settings.settings_sw_text_caption_hp &&
    results.settings.settings_sw_text_caption_hp.question &&
    results.settings.settings_sw_text_caption_hp.question !== ''
  ) {
    wtSettings.heatingType.HP.text = results.settings.settings_sw_text_caption_hp.question
  }

  if (
    results.settings.settings_sw_text_caption_oil &&
    results.settings.settings_sw_text_caption_oil.question &&
    results.settings.settings_sw_text_caption_oil.question !== ''
  ) {
    wtSettings.heatingType.OIL.text = results.settings.settings_sw_text_caption_oil.question
  }

  if (
    results.settings.settings_sw_text_form_send_success &&
    results.settings.settings_sw_text_form_send_success.question &&
    results.settings.settings_sw_text_form_send_success.question !== ''
  ) {
    wtSettings.form.text.sendSuccess = results.settings.settings_sw_text_form_send_success.question
  }

  if (
    results.settings.settings_sw_text_form_accept_data_protection_checkbox_text &&
    results.settings.settings_sw_text_form_accept_data_protection_checkbox_text.question &&
    results.settings.settings_sw_text_form_accept_data_protection_checkbox_text.question !== ''
  ) {
    wtSettings.form.text.dataProtectionCheckboxText =
      results.settings.settings_sw_text_form_accept_data_protection_checkbox_text.question
  }

  if (
    results.settings.settings_sw_text_form_data_protection_url &&
    results.settings.settings_sw_text_form_data_protection_url.question &&
    results.settings.settings_sw_text_form_data_protection_url.question !== ''
  ) {
    wtSettings.form.text.dataProtectionUrl =
      results.settings.settings_sw_text_form_data_protection_url.question
  }

  if (
    results.settings.settings_sw_text_form_data_imprint_url &&
    results.settings.settings_sw_text_form_data_imprint_url.question &&
    results.settings.settings_sw_text_form_data_imprint_url.question !== ''
  ) {
    wtSettings.form.text.dataImprintUrl =
      results.settings.settings_sw_text_form_data_imprint_url.question
  }

  if (
    results.settings.settings_sw_text_heat_tool_name &&
    results.settings.settings_sw_text_heat_tool_name.question &&
    results.settings.settings_sw_text_heat_tool_name.question !== ''
  ) {
    wtSettings.basics.text.heatToolName = results.settings.settings_sw_text_heat_tool_name.question
  }

  if (
    results.settings.settings_sw_boolean_compare ||
    results.settings.settings_sw_boolean_compare === 'true'
  ) {
    wtSettings.pricePage.showComparison = true
  } else {
    wtSettings.pricePage.showComparison = false
  }

  if (results!.settings!.settings_sw_text_plz!.question) {
    wtSettings.basics.text.plz = results!.settings!.settings_sw_text_plz!.question.split(', ')
  }

  if (
    results.settings.settings_sw_text_form_cross_selling_text &&
    results.settings.settings_sw_text_form_cross_selling_text.question &&
    results.settings.settings_sw_text_form_cross_selling_text.question !== ''
  ) {
    wtSettings.form.crossSellingCheckboxes = results.settings.settings_sw_text_form_cross_selling_text.question.split(
      ','
    )
  } else {
    wtSettings.form.crossSellingCheckboxes = []
  }

  // this.contentStore.prepareForm(wtSettings)
  // console.log('_________ wt: ', wtSettings)

  return wtSettings
}

export const setValueId = (options: SelectOptionType[], value: string) => {
  const valueId = options.find((option: SelectOptionType) => option.value === value)!.value_id
  console.log('_____ value id:', valueId)
  return valueId
}

export const findCostValueForAnswer = (fields: any, fieldKey?: string) => {
  const fieldIndex = fieldKey!.slice(0, -6)
  let absolute: number = 0
  let procentual: number = 0

  if (fieldKey) {
    Object.keys(fields).forEach((field: string) => {
      if (!field.includes('value') && field.includes(fieldIndex)) {
        if (field.includes('absolute')) {
          // console.log(fields[`${fieldIndex}_cost_absolute`])
          absolute = parseInt(fields[`${fieldIndex}_cost_absolute`])
        }
        if (field.includes('procentual')) {
          // console.log(fields[`${fieldIndex}_cost_procentual`])
          procentual = parseInt(fields[`${fieldIndex}_cost_procentual`])
        }
      }
    })
  }
  console.log(' from function_____ absolute: ', absolute, '_____ procentual: ', procentual)
  return { absolute, procentual }
}

export const calculateCostValue = (
  type: string,
  selectSubType: string,
  questions: any[],
  key: string,
  value: string | string[]
) => {
  let absolute: number = 0
  let procentual: number = 0

  // type: 'multi' or 'text', text-type has no 'cost-xxx' attribute
  if (type === 'multi') {
    // get all fields attribute of the question
    // const fields = toJS(this.appStore.questions).find((question: any) => question.question === key)!
    //   .fields
    const fields = questions.find((question: any) => question.question === key)!.fields

    // check if value is string or array
    if (selectSubType === 'multi' && typeof value === 'object') {
      value.forEach((elem: string) => {
        const fieldKey = Object.keys(fields).find((field: string) => fields[field] === elem)
        // const result = findCostValueForAnswer(fields, fieldKey)
        absolute = absolute + findCostValueForAnswer(fields, fieldKey).absolute
        procentual = procentual + findCostValueForAnswer(fields, fieldKey).procentual
      })
      return { absolute, procentual }
    } else {
      // find the attributes (field_x_value) of the answer,
      // to find field_x__cost_absolute and field_x__cost_procentual
      const fieldKey = Object.keys(fields).find((field: string) => fields[field] === value)
      return findCostValueForAnswer(fields, fieldKey)
      // costAbsolute = findCostValueForAnswer(fields, fieldKey).absolute
      // costAbsolute = findCostValueForAnswer(fields, fieldKey).procentual
      // const { absolute, procentual } = result
      // costAbsolute = absolute
      // costAbsolute = procentual
    }
  } else {
    return { absolute: 0, procentual: 0 }
  }
}

export const sumCostValueOfAdditionalAnswers = (additionalAnswers: any[]) => {
  let sumAbsolute: number = 0
  let sumProcentual: number = 0

  additionalAnswers.forEach((answer: any) => {
    sumAbsolute = sumAbsolute + answer.cost.absolute
    sumProcentual = sumProcentual + answer.cost.procentual
  })
  return { sumAbsolute, sumProcentual }
}
