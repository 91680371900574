import React, { useContext } from 'react'
import { DeviceContext, DeviceType, DocumentHead } from '../utils'
import { HomeContent, HomeContentMoible } from './page'

interface HomeContainerProps {
  appTitle: string
}
export const HomeContainer: React.FC<HomeContainerProps> = ({ appTitle }) => {
  const { device } = useContext(DeviceContext)

  return (
    <React.Fragment>
      <DocumentHead title={appTitle} />
      <React.Fragment>
        {device === DeviceType.LAPTOP ? (
          <HomeContent appTitle={appTitle} />
        ) : (
          <HomeContentMoible appTitle={appTitle} />
        )}
      </React.Fragment>
    </React.Fragment>
  )
}

export default HomeContainer
