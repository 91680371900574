import React, { useState } from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { AppTheme } from '../../../theme'

interface TextFieldProps extends TextFieldStyleProps {
  value: string
  name: string
  isRequired?: boolean
  placeholder?: string
  hasError?: boolean
  errorMsg?: string
  onInputChange?(event: React.ChangeEvent<HTMLInputElement>): void
  setValueFor?(key: string, value: string): void
}

export const TextField: React.FC<TextFieldProps> = ({
  name,
  isRequired,
  placeholder,
  hasError,
  errorMsg,
  onInputChange,
  position,
  width,
  isInline,
  value,
  setValueFor
}) => {
  const [inputValue, setInputValue] = useState(value)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.currentTarget.value)
    if (onInputChange) {
      onInputChange(event)
    }

    if (!!setValueFor) {
      setValueFor(name, event.currentTarget.value)
    }
  }

  return (
    <TextFieldContainer position={position} width={width}>
      <StyledTextField
        marginY={AppTheme.spacingSize.M}
        name={name}
        // onFocus={event => handleKeyboardInputFocus(event)}
        onChange={(event) => handleInputChange(event)}
        paddingX={AppTheme.spacingSize.S}
        placeholder={isRequired ? placeholder + '*' : placeholder}
        isRequired={isRequired}
        value={inputValue}
        isInline={isInline}
        hasError={hasError}
      />
      {hasError && <HintText>{errorMsg}</HintText>}
    </TextFieldContainer>
  )
}

TextField.defaultProps = {
  position: 'relative'
}

interface TextFieldStyleProps {
  isInline?: boolean
  position?: string
  width?: string | number
}

const TextFieldContainer = styled.div<TextFieldStyleProps>`
  width: ${(props) => (props.width ? props.width : '100%')};
  position: ${(props) => props.position};
`

const StyledTextField = styled.input<SpaceProps & TextFieldProps>`
  ${space}
  display:${(props) => (props.isInline ? 'inline-block' : 'block')};
  border: ${(props) => (props.hasError ? 'solid 1px #f44336' : 'solid 1px #eee')};
  width: 100%;
  height: 40px;
  font-weight: ${(props) => props.theme.fonts.weights.light};
  font-size: ${(props) => props.theme.fonts.sizes.size_Form}px;
  outline: none;

  :focus {
    outline: none;
  }

  ::placeholder {
    font-weight: lighter;
    color: ${(props) => props.theme.colors.darkgray};
    font-family: ${(props) => props.theme.fonts.family};
    font-size: ${(props) => props.theme.fonts.sizes.size_Form}px;
  }
`

const HintText = styled.div`
  font-size: 11px;
  position: absolute;
  color: red;
  top: 5px;
  right: 5px;
  font-family: ${(props) => props.theme.fonts.family};
`

export default TextField
