import React from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Path, UseFormRegister, FieldError } from 'react-hook-form'
import { IFormValues } from '../../template/Contact/component'

interface InputFieldProps {
  label: Path<IFormValues>
  register: UseFormRegister<IFormValues>
  required?: boolean
  error?: FieldError | undefined
  placeholder?: string
}

export const InputField: React.FC<InputFieldProps> = ({
  label,
  register,
  required = false,
  placeholder,
  error
}) => {
  const placeholderText = required ? `${placeholder}*` : placeholder

  return (
    <TextFieldContainer>
      <StyledInputField
        {...register(label, {
          required: {
            value: required,
            message: 'Pflichtfeld'
          },
          pattern: {
            value: label === 'email' ? /\S+@\S+\.\S+/ : /[\s\S]*/,
            message: 'Email nicht gültig'
          }
        })}
        placeholder={placeholderText}
        hasError={Boolean(error)}
      />
      {Boolean(error) && <HintText>{error?.message}</HintText>}
    </TextFieldContainer>
  )
}

const TextFieldContainer = styled.div`
  width: 100%;
  position: relative;
`

const StyledInputField = styled.input<SpaceProps & { hasError: boolean }>`
  ${space}
  width: 100%;
  height: 40px;
  font-weight: ${(props) => props.theme.fonts.weights.light};
  font-size: ${(props) => props.theme.fonts.sizes.size_Form}px;
  outline: none;
  color: ${(props) => props.theme.colors.black};
  border: ${(props) => (props.hasError ? 'solid 1px #f44336' : 'solid 1px #eee')};
  padding: ${(props) => props.theme.spacingSize.XS}px;

  :focus {
    outline: none;
    outline-width: 0;
    background-color: white;
    border: ${(props) => (props.hasError ? 'solid 1px #f44336' : 'solid 1px #eee')};
  }

  ::placeholder {
    font-weight: lighter;
    color: ${(props) => props.theme.colors.gray};
    font-family: ${(props) => props.theme.fonts.family};
    font-size: ${(props) => props.theme.fonts.sizes.size_Form}px;
  }
`

const HintText = styled.div`
  font-size: 11px;
  position: absolute;
  color: red;
  top: 5px;
  right: 5px;
  font-family: ${(props) => props.theme.fonts.family};
`

export default InputField
