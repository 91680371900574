import React, { ReactElement, ReactNode } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { Displays } from '../../../theme'
interface ButtonProps extends ButtonStyleProps {
  children: string | ReactElement | ReactNode
  onClick?(): void
}
export const Button: React.FC<ButtonProps> = ({
  children,
  display,
  isUnderlined,
  hasBorder,
  onClick,
  maxWidth,
  animated,
  visible,
  hasIcon,
  color,
  fontSize
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <ButtonContainer
      fontSize={fontSize}
      display={display}
      onClick={handleClick}
      className='button'
      animated={animated}
      visible={visible}>
      {hasIcon ? (
        <>{children}</>
      ) : (
        <>
          {hasBorder ? (
            <ButtonOuterWrapper>
              <ButtonWithBorder>{children}</ButtonWithBorder>
            </ButtonOuterWrapper>
          ) : (
            <TextButton isUnderlined={isUnderlined} maxWidth={maxWidth} color={color}>
              {children}
            </TextButton>
          )}
        </>
      )}
    </ButtonContainer>
  )
}

Button.defaultProps = {
  hasBorder: true,
  display: Displays.INLINE_BLOCK,
  animated: false,
  visible: true,
  hasIcon: false
}

interface ButtonStyleProps {
  visible?: boolean
  animated?: boolean
  maxWidth?: string
  hasBorder?: boolean
  isUnderlined?: boolean
  display?: Displays.BLOCK | Displays.INLINE_BLOCK
  hasIcon?: boolean
  color?: string
  fontSize?: number
}

const zoom = keyframes`
  0% {transform: scale(1);}
  50% {transform: scale(1.5);}
  100% {transform: scale(1);}
`

const animation = css`
  animation: ${zoom} 2s ease 1s 1;
`

const ButtonContainer = styled.div<ButtonStyleProps>`
  display: ${(props) => props.display};
  font-family: ${(props) => props.theme.fonts.family};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : props.theme.fonts.sizes.size_Button}px;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
  ${(props) => props.animated && animation};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`

const TextButton = styled.div<Partial<ButtonStyleProps>>`
  width: auto;
  max-width: ${(props) => props.maxWidth};
  color: ${(props) => (props.color ? props.color : props.theme.colors.secondary)};
  transition: all 0.3s ease;
  &:hover {
    text-decoration: ${(props) => props.isUnderlined && 'underline'};
    color: ${(props) => !props.isUnderlined && props.theme.colors.primary};
  }
`

const ButtonOuterWrapper = styled.div`
  display: block;
  border-radius: 5px;
  padding: 5px;
  background-color: ${(props) => props.theme.colors.secondary};
`

const ButtonWithBorder = styled.div`
  height: auto;
  width: auto;
  padding: 10px 15px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.white};
  appearance: none;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px 2px;
  color: ${(props) => props.theme.colors.secondary};
  transition: all 0.3s ease;
  &:active {
    box-shadow: none;
  }
`

export default Button
