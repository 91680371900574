import React, { useContext } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { readableColor } from 'polished'
import { AppTheme } from '../../../theme'
import { Card, FlexContainer, Space, Typography } from '../../atoms'
import { Contact, EmailFeedback, Questions, Result } from '../../template'
import { AppStoreContext } from '../../../utils'
import { LinkFooter } from '../../molecules'

interface HomeContentProps {
  appTitle: string
}

export const HomeContent: React.FC<HomeContentProps> = observer(({ appTitle }) => {
  const store = useContext(AppStoreContext)

  const handleExpandContact = () => {
    store.uiStore.updateLeftSideWidth(store.uiStore.showContact ? 65 : 35)
    store.uiStore.updateShowContact(true)
    store!.calculateCost()
  }

  const handleBackToQuestions = () => {
    store.uiStore.updateLeftSideWidth(65)
    store.uiStore.updateShowContact(false)
    store.uiStore.updateEmailState('')
  }

  return (
    <Container>
      <LeftSideContainer width={`${store.uiStore.leftSideWidth}%`}>
        <TitleContainer>
          <Typography
            color={readableColor(
              AppTheme.colors.primary,
              AppTheme.colors.stronggray,
              AppTheme.colors.white
            )}>
            {appTitle}
          </Typography>
        </TitleContainer>
        <Space
          marginTop={`${AppTheme.spacingSize.S + AppTheme.spacingSize.XL}px`}
          height='calc(100% - 44px)'>
          <Card>
            <Space
              py={AppTheme.spacingSize.XL}
              pr={AppTheme.spacingSize.XL}
              pl={AppTheme.spacingSize.L}>
              {!store.uiStore.showContact ? (
                <Questions />
              ) : store.uiStore.emailState === '' ? (
                <Contact />
              ) : (
                <EmailFeedback succeeded={store.uiStore.emailState === 'succeeded'} />
              )}
            </Space>
          </Card>
        </Space>
      </LeftSideContainer>

      <RightSiderContainer width={`${100 - store.uiStore.leftSideWidth}%`}>
        <Space
          marginTop={`${AppTheme.spacingSize.S + AppTheme.spacingSize.XL}px`}
          height='calc(100% - 44px)'>
          <Card>
            <Space padding={AppTheme.spacingSize.XL}>
              <FlexContainer
                style={{ height: '100%' }}
                flexDirection='column'
                justifyContent='space-between'
                alignItems='flex-end'>
                <Result
                  onShowResult={handleExpandContact}
                  expanded={store.uiStore.showContact}
                  isEmailSent={store.isEmailSent}
                  onBackToQuestions={handleBackToQuestions}
                />
                <LinkFooter />
              </FlexContainer>
            </Space>
          </Card>
        </Space>
      </RightSiderContainer>
    </Container>
  )
})

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

interface ContainerStyleProps {
  width: string
}

const TitleContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 44px;
  text-align: center;
`

const LeftSideContainer = styled.div<ContainerStyleProps>`
  width: ${(props) => props.width};
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors.primary};
  transition: all 0.5s ease;
`
const RightSiderContainer = styled.div<ContainerStyleProps>`
  width: ${(props) => props.width};
  height: 100%;
  position: relative;
  background-color: ${(props) => props.theme.colors.secondary};
  transition: all 0.5s ease;
`

export default HomeContent
