import { observer } from 'mobx-react-lite'
import React, { useContext, useState } from 'react'
import { AppTheme } from '../../../theme'
import { AppStoreContext, DeviceContext, DeviceType } from '../../../utils'
import { RangeSliderWithCustomStep, Select, Space, Typography } from '../../atoms'
import { Accordion, AccordionMobile } from '../../molecules'
import {
  convertAreaToSteps,
  BuildingYearSteps,
  standardQuestionsConfig,
  StandardAnswersType
} from './static'

const AreaSteps = convertAreaToSteps()
export const StandardQuestions: React.FC = observer(() => {
  const { device } = useContext(DeviceContext)
  const store = useContext(AppStoreContext)
  const questionStore = store?.questionStore

  const initialBuildYear = Math.floor((BuildingYearSteps.length - 1) / 2)
  const buildYearIndex = BuildingYearSteps.findIndex(
    (el: string) => el === questionStore!.buildYear
  )
  const [hasBuildYearChanged, setHasBuildYearChanged] = useState(false)

  const initialBuildingArea = Math.floor((AreaSteps.length - 1) / 2)
  const [hasAreaChanged, setHasAreaChanged] = useState(false)
  const buildAreaIndex = AreaSteps.findIndex((el: string) => el === questionStore!.buildingArea)

  const heatType = !!store
    ? store.questionStore.standardAnswers.find(
        (item: StandardAnswersType) => item.name === standardQuestionsConfig[3].name
      )!.value
    : ''

  return (
    <>
      {!!store && !!questionStore && (
        <>
          {device === DeviceType.MOBILE ? (
            <div>
              <AccordionMobile
                index={0}
                isOpen={questionStore.accordionActiveStatesForStandard[0].active}
                title={standardQuestionsConfig[0].title}
                description={standardQuestionsConfig[0].question}
                onHide={questionStore.openNextAccordion}
                onOpen={questionStore.openOneAccordionAndCloseOthers}
                noteUnderTitle={questionStore.standardAnswers[0].value}
                showResultBtn={questionStore.isResultMobileTriggered}>
                <Select
                  name={standardQuestionsConfig[0].name}
                  isMultiple={false}
                  selectedOptions={questionStore.buildingTypeOptions}
                  setSelectedOptions={questionStore.updateBuildingType}
                  setValueFor={questionStore.updateStandardAnswers}
                />
              </AccordionMobile>

              <AccordionMobile
                index={1}
                isOpen={questionStore.accordionActiveStatesForStandard[1].active}
                onHide={questionStore.openNextAccordion}
                onOpen={questionStore.openOneAccordionAndCloseOthers}
                title={standardQuestionsConfig[1].title}
                description={standardQuestionsConfig[1].question}
                noteUnderTitle={questionStore.buildYear}
                showResultBtn={questionStore.isResultMobileTriggered}>
                <RangeSliderWithCustomStep
                  name={standardQuestionsConfig[1].name}
                  steps={BuildingYearSteps}
                  value={questionStore.buildYear !== '' ? buildYearIndex : initialBuildYear}
                  setValue={questionStore.updateBuildYear}
                  setValueFor={questionStore.updateStandardAnswers}
                  hasChanged={hasBuildYearChanged}
                  setHasChanged={setHasBuildYearChanged}
                  output={
                    !!questionStore.buildYear ? BuildingYearSteps[buildYearIndex] : 'Bitte wählen'
                  }
                />
              </AccordionMobile>

              <AccordionMobile
                index={2}
                isOpen={questionStore.accordionActiveStatesForStandard[2].active}
                onHide={questionStore.openNextAccordion}
                onOpen={questionStore.openOneAccordionAndCloseOthers}
                title={standardQuestionsConfig[2].title}
                description={standardQuestionsConfig[2].question}
                noteUnderTitle={questionStore.buildingArea}
                showResultBtn={questionStore.isResultMobileTriggered}>
                <RangeSliderWithCustomStep
                  name={standardQuestionsConfig[2].name}
                  steps={AreaSteps}
                  value={questionStore.buildingArea !== '' ? buildAreaIndex : initialBuildingArea}
                  setValue={questionStore.updateBuildingArea}
                  setValueFor={questionStore.updateStandardAnswers}
                  hasChanged={hasAreaChanged}
                  setHasChanged={setHasAreaChanged}
                  output={!!questionStore.buildingArea ? AreaSteps[buildAreaIndex] : 'Bitte wählen'}
                />
              </AccordionMobile>

              <AccordionMobile
                index={3}
                isOpen={
                  questionStore.isResultMobileTriggered
                    ? false
                    : questionStore.accordionActiveStatesForStandard[3].active
                }
                isLast={true}
                onHide={
                  questionStore.allStandardQuestionAnswered
                    ? () => questionStore.showResultMobile(true)
                    : questionStore.openNextAccordion
                }
                onOpen={questionStore.openOneAccordionAndCloseOthers}
                title={standardQuestionsConfig[3].title}
                description={standardQuestionsConfig[3].question}
                noteUnderTitle={questionStore.standardAnswers[3].value}
                showResultBtn={questionStore.isResultMobileTriggered}>
                <Select
                  name={standardQuestionsConfig[3].name}
                  isMultiple={false}
                  selectedOptions={questionStore.heatingTypes}
                  setSelectedOptions={questionStore.updateHeatingType}
                  setValueFor={questionStore.updateStandardAnswers}
                />
                {heatType !== 'Erdgas' &&
                  heatType !== '' &&
                  !!standardQuestionsConfig[3].additional && (
                    <Space mb={`${AppTheme.spacingSize.S}px`} height='auto'>
                      <Typography fontSize={`${AppTheme.fonts.sizes.size_H2}px`} lineHeight='4em'>
                        {standardQuestionsConfig[3].additional.question}
                      </Typography>
                      <Select
                        name={standardQuestionsConfig[3].additional.name}
                        isMultiple={false}
                        setValueFor={questionStore.updateStandardAnswers}
                        selectedOptions={questionStore.gasConnectionTypes}
                        setSelectedOptions={questionStore.updateGasConnection}
                      />
                    </Space>
                  )}
              </AccordionMobile>
            </div>
          ) : (
            <>
              <Accordion
                isOpen={questionStore.accordionActiveStatesForStandard[0].active}
                title={standardQuestionsConfig[0].title}
                description={standardQuestionsConfig[0].question}
                onClose={questionStore.openNextAccordion}
                onOpen={questionStore.openOneAccordionAndCloseOthers}
                index={0}
                noteUnderTitle={questionStore.standardAnswers[0].value}>
                <Select
                  name={standardQuestionsConfig[0].name}
                  isMultiple={false}
                  selectedOptions={questionStore.buildingTypeOptions}
                  setSelectedOptions={questionStore.updateBuildingType}
                  setValueFor={questionStore.updateStandardAnswers}
                />
              </Accordion>

              <Accordion
                index={1}
                isOpen={questionStore.accordionActiveStatesForStandard[1].active}
                onClose={questionStore.openNextAccordion}
                onOpen={questionStore.openOneAccordionAndCloseOthers}
                title={standardQuestionsConfig[1].title}
                description={standardQuestionsConfig[1].question}
                noteUnderTitle={questionStore.buildYear}>
                <RangeSliderWithCustomStep
                  name={standardQuestionsConfig[1].name}
                  steps={BuildingYearSteps}
                  value={questionStore.buildYear !== '' ? buildYearIndex : initialBuildYear}
                  setValue={questionStore.updateBuildYear}
                  setValueFor={questionStore.updateStandardAnswers}
                  hasChanged={hasBuildYearChanged}
                  setHasChanged={setHasBuildYearChanged}
                  output={
                    !!questionStore.buildYear ? BuildingYearSteps[buildYearIndex] : 'Bitte wählen'
                  }
                />
              </Accordion>

              <Accordion
                index={2}
                isOpen={questionStore.accordionActiveStatesForStandard[2].active}
                onClose={questionStore.openNextAccordion}
                onOpen={questionStore.openOneAccordionAndCloseOthers}
                title={standardQuestionsConfig[2].title}
                description={standardQuestionsConfig[2].question}
                noteUnderTitle={questionStore.buildingArea}>
                <RangeSliderWithCustomStep
                  name={standardQuestionsConfig[2].name}
                  steps={AreaSteps}
                  value={questionStore.buildingArea !== '' ? buildAreaIndex : initialBuildingArea}
                  setValue={questionStore.updateBuildingArea}
                  setValueFor={questionStore.updateStandardAnswers}
                  hasChanged={hasAreaChanged}
                  setHasChanged={setHasAreaChanged}
                  output={!!questionStore.buildingArea ? AreaSteps[buildAreaIndex] : 'Bitte wählen'}
                />
              </Accordion>

              <Accordion
                index={3}
                isOpen={
                  questionStore.showAdditionalQuestions
                    ? false
                    : questionStore.accordionActiveStatesForStandard[3].active
                }
                onClose={questionStore.openNextAccordion}
                onOpen={questionStore.openOneAccordionAndCloseOthers}
                showContinueBtn={questionStore.showAdditionalQuestions}
                title={standardQuestionsConfig[3].title}
                description={standardQuestionsConfig[3].question}
                noteUnderTitle={questionStore.standardAnswers[3].value}>
                <Select
                  name={standardQuestionsConfig[3].name}
                  isMultiple={false}
                  selectedOptions={questionStore.heatingTypes}
                  setSelectedOptions={questionStore.updateHeatingType}
                  setValueFor={questionStore.updateStandardAnswers}
                />
                {heatType !== 'Erdgas' &&
                  heatType !== '' &&
                  !!standardQuestionsConfig[3].additional && (
                    <Space mb={`${AppTheme.spacingSize.L}px`} height='auto'>
                      <Typography
                        fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
                        lineHeight='4em'
                        color={AppTheme.colors.darkgray}>
                        {standardQuestionsConfig[3].additional.question}
                      </Typography>
                      <Select
                        name={standardQuestionsConfig[3].additional.name}
                        isMultiple={false}
                        setValueFor={questionStore.updateStandardAnswers}
                        selectedOptions={questionStore.gasConnectionTypes}
                        setSelectedOptions={questionStore.updateGasConnection}
                      />
                    </Space>
                  )}
              </Accordion>
            </>
          )}
        </>
      )}
    </>
  )
})

// interface ContainerProps {
//   height: string
//   // overflow: string
//   transform?: string
// }

// const StandardQuestionsContainer = styled.div<ContainerProps>`
//   height: ${(props) => props.height};
//   transform: ${(props) => props.transform};
//   transition: all 0.3s ease;
// `

export default StandardQuestions
