import React from 'react'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'
import { Displays } from '../../../theme'

interface SpacingProps extends SpaceStyleProps {
  className?: string
  children?: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[]

  height?: string
}

type DisplayTypes = Displays.INLINE | Displays.INLINE_BLOCK | Displays.BLOCK

interface SpaceStyleProps {
  display?: DisplayTypes
  overflow?: string
}

export const Space = styled.div.attrs({ className: 'space' })<SpacingProps & SpaceProps>`
  ${space}
  display: ${(props) => props.display};
  overflow: ${(props) => props.overflow};
  height: ${(props) => (props.height ? props.height : '100%')};
`

export default Space
