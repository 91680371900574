import { readableColor } from 'polished'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { AppTheme } from '../../../theme'
import { Space } from '../Space'
interface RangeSliderWithCustomStep {
  steps: string[]
  value: number
  setValue(val: number): void
  setValueFor?(key: string, value: string): void
  name?: string
  hasChanged?: boolean
  setHasChanged?(hasChanged: boolean): void
  output?: string
}

export const RangeSliderWithCustomStep: React.FC<RangeSliderWithCustomStep> = ({
  steps,
  value,
  setValue,
  name,
  setHasChanged,
  setValueFor,
  output
}) => {
  const rangeOutputRef = useRef<HTMLOutputElement>(null)
  const rangeInputRef = useRef<HTMLInputElement>(null)

  const [outputValue, setOutputValue] = useState(output)
  const [outputLeftPosition, setOutputLeftPosition] = useState(
    `${(value / (steps.length - 1)) * 100}%`
  )

  function handleChangeValue(event: React.ChangeEvent<HTMLInputElement>) {
    // tslint:disable-next-line: radix
    const inputValue = parseInt(event.target.value)

    setValue(inputValue)
    setOutputValue(steps[inputValue])
    setOutput(inputValue)
    if (setHasChanged) {
      setHasChanged(true)
    }

    if (!!name && setValueFor) {
      setValueFor(name, steps[inputValue])
    }
  }

  const setOutput = (inputValue: number) => {
    const min = 0
    const max = steps.length - 1
    const newVal = Number(((inputValue - min) * 100) / (max - min))
    setOutputValue(steps[inputValue])
    setOutputLeftPosition(`calc(${newVal}% + (${8 - newVal * 0.15}px))`)
    // Sorta magic numbers based on size of the native UI thumb
  }

  return (
    <Space mb={`${AppTheme.spacingSize.XL}px`} height='auto'>
      <RangeContainer className='custom_range_slider'>
        <OutputContainer>
          <RangeOutput
            className='range_slider_output'
            name={name}
            left={outputLeftPosition}
            ref={rangeOutputRef}>
            {outputValue}
          </RangeOutput>
        </OutputContainer>
        <RangeInput
          type='range'
          className='range_slider_input'
          ref={rangeInputRef}
          name={name}
          min={0}
          max={steps.length - 1}
          step={1}
          value={value}
          onChange={handleChangeValue}
        />
      </RangeContainer>
    </Space>
  )
}

const OutputContainer = styled.div`
  position: relative;
  height: 50px;
  width: 98%;
  margin: 0 auto;
`
export const RangeContainer = styled.div`
  width: 100%;
  position: relative;
  font-family: ${(props) => props.theme.fonts.family};
`
export const RangeInput = styled.input`
  width: 100%;
  display: block;
  height: 35px;
  appearance: none;
  margin: 12px 0px;
  padding: 2px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.lightgray};
  outline: none;
  box-shadow: inset 2px 2px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  overflow: visible;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: none;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
  }

  &::-moz-range-thumb {
    width: 29px;
    height: 31px;
    border-radius: 100%;
    border: none;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    padding-left: 2px;
    padding-right: 2px;
  }
`

interface RangeOutputStyleProps {
  left?: string
}

export const RangeOutput = styled.output<RangeOutputStyleProps>`
  position: absolute;
  left: ${(props) => props.left};
  transform: translateX(-50%);
  display: table;
  padding: 12px 7px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.secondary};
  white-space: nowrap;
  color: ${(props) =>
    readableColor(
      props.theme.colors.secondary,
      props.theme.colors.stronggray,
      props.theme.colors.white
    )};
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    /* margin-left: -8px; */
    width: 0;
    height: 0;
    border-top: solid 8px ${(props) => props.theme.colors.secondary};
    border-left: solid 8px transparent;
    border-right: solid 8px transparent;
  }
`

export default RangeSliderWithCustomStep
