import React from 'react'
import styled from 'styled-components'

export const Divider: React.FC = () => {
  return <StyledDivider />
}

const StyledDivider = styled.div`
  width: 100%;
  display: block;
  background-color: ${(props) => props.theme.colors.lightgray};
  height: 1px;
`

export default Divider
