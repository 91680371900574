import React, { ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { SubmitHandler, useForm } from 'react-hook-form'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {
  AppointmentDays,
  AppointmentDaytime,
  ContactFormFields,
  ContactUserDataType
} from './static'
import { AppStoreContext } from '../../../utils'
import { AppTheme } from '../../../theme'
import {
  FlexContainer,
  InputField,
  Link,
  Select,
  SelectOptionType,
  Space,
  Switch,
  Typography
} from '../../atoms'

export interface IFormValues {
  customerNumber: string
  firstName: string
  lastName: string
  email: string
  tel: string
  street: string
  zip: string
  city: string
  comment: string
}

export const Contact: React.FC = () => {
  const store = useContext(AppStoreContext)

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm<IFormValues>()

  const [isOwner, setIsOwner] = useState(false)

  const [dataProtection, setDataProtection] = useState('')
  const [dataProtectionUrl, setDataProtectionUrl] = useState('')

  const [selectedDay, setSelectedDay] = useState(AppointmentDays)
  const [selectedAppoitmentTime, setSelectedAppoitmentTime] = useState(AppointmentDaytime)
  const [selectedService, setSelectedService] = useState(store.mapDataToInterestedServices())
  const [policyAgreed, setPolicyAgreed] = useState(false)
  const [isPolicyError, setIsPolicyError] = React.useState(false)

  const [sentEmail, setSentEmail] = useState(false)

  useEffect(() => {
    if (!!store) {
      setDataProtection(store.wtSettings.form.text.dataProtectionCheckboxText)
      setDataProtectionUrl(store.wtSettings.form.text.dataProtectionUrl)
      setSelectedService(store.mapDataToInterestedServices())
    }
  }, [store])

  const renderDataschutz = () => {
    return (
      <Typography color={isPolicyError ? AppTheme.colors.red : AppTheme.colors.darkgray}>
        <>
          <>{dataProtection + ' '}</>
          <Link href={dataProtectionUrl} target='_blank'>
            {'Datenschutzerklärung öffnen'}
          </Link>
        </>
      </Typography>
    )
  }

  const handleCheckPolicyAgreement = () => {
    setPolicyAgreed(!policyAgreed)
    setIsPolicyError(!!policyAgreed)
  }

  const reformUserInputData = (formData: any) => {
    const data: ContactUserDataType = {
      customer: { address: {} },
      appointment: {},
      interested: []
    }
    // customer
    data.customer.isOwner = isOwner
    Object.keys(formData).forEach((key: string) => {
      if (key === 'street' || key === 'zip' || key === 'city') {
        data.customer.address[`${key}`] = formData[key] as string
      } else {
        data.customer[`${key}`] = formData[key] as string
      }
    })

    // apointment
    const days: string[] = []
    selectedDay.forEach((day: SelectOptionType) => {
      if (day.checked) {
        days.push(day.value)
      }
    })
    const daytime: string[] = []
    selectedAppoitmentTime.forEach((appointment: SelectOptionType) => {
      if (appointment.checked) {
        daytime.push(appointment.value)
      }
    })
    data.appointment.days = days
    data.appointment.daytime = daytime

    // interested
    const interested: string[] = []
    !!selectedService &&
      selectedService.forEach((service: SelectOptionType) => {
        if (service.checked) {
          interested.push(service.value)
        }
      })
    data.interested = interested

    return data
  }

  const onSubmit: SubmitHandler<IFormValues> = (formData) => {
    if (!isPolicyError && policyAgreed) {
      const useData = reformUserInputData(formData)
      store.sendEmail(useData)
      setSentEmail(true)
      // onSendEmail()
    } else {
      const ContactAlert = ({
        onClose
      }: {
        title: string
        message: string
        onClose: () => void
      }): JSX.Element => {
        return (
          <ThemeProvider theme={AppTheme}>
            <AlertContainer onClick={onClose}>
              <div>
                <Typography fontSize={`${AppTheme.fonts.sizes.size_H2}px`} lineHeight='4em'>
                  Nicht gesendet
                </Typography>
                <Typography fontSize={`${AppTheme.fonts.sizes.size_Paragraph_L}px`}>
                  Bitte korrigieren Sie die rot hinterlegten Eingaben
                </Typography>
              </div>
            </AlertContainer>
          </ThemeProvider>
        )
      }
      confirmAlert({
        customUI: ContactAlert
      })
    }
  }

  return (
    <ContactContainer className='contact'>
      <Space paddingBottom={`${AppTheme.spacingSize.L}px`} height='auto'>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FlexContainer
            flexDirection='column'
            gap='12px'
            style={{ marginBottom: `${AppTheme.spacingSize.L}px` }}>
            <Switch label='Eigentümer' name='isOwner' setChecked={setIsOwner} checked={isOwner} />

            {ContactFormFields.map((field) => (
              <InputField
                key={field.fieldName}
                label={field.fieldName}
                placeholder={field.placeholder}
                register={register}
                required={field.required}
                error={errors[field.fieldName]}
              />
            ))}

            <FlexContainer flexDirection='row' gap='12px'>
              <div style={{ flex: '1' }}>
                <InputField
                  label='zip'
                  register={register}
                  required
                  placeholder='PLZ'
                  error={errors.zip}
                />
              </div>
              <div style={{ flex: '3' }}>
                <InputField
                  label='city'
                  register={register}
                  required
                  placeholder='Ort'
                  error={errors.city}
                />
              </div>
            </FlexContainer>
          </FlexContainer>

          <SectionContainer>
            <>
              <Typography>{'Ich bin erreichbar'}</Typography>
              <Space my={`${AppTheme.spacingSize.L}px`}>
                <Select
                  isMultiple
                  selectedOptions={selectedDay}
                  setSelectedOptions={setSelectedDay}
                  itemWidth='70%'
                  isInline={false}
                />
              </Space>
              <Select
                isMultiple
                selectedOptions={selectedAppoitmentTime}
                setSelectedOptions={setSelectedAppoitmentTime}
              />
            </>
          </SectionContainer>

          <SectionContainer>
            <>
              <Typography>{'Ich interessiere mich außerdem für'}</Typography>
              <Space mt={`${AppTheme.spacingSize.L}px`}>
                <Select
                  isMultiple
                  selectedOptions={selectedService}
                  setSelectedOptions={setSelectedService}
                />
              </Space>
            </>
          </SectionContainer>

          <SectionContainer>
            <Switch
              checked={policyAgreed}
              setChecked={handleCheckPolicyAgreement}
              label={renderDataschutz()}
              name='policy_agreed'
            />
          </SectionContainer>

          <SectionContainer>
            <FlexContainer justifyContent='space-between' alignItems='center'>
              <Typography color={AppTheme.colors.gray}>{'*Pflichtangeben'}</Typography>
              <ButtonOuterWrapper disabled={sentEmail}>
                <ButtonWithBorder className='btn btn-lg pro' id='submit' disabled={sentEmail}>
                  {sentEmail && store.uiStore.emailState === '' ? 'Sending' : 'Absenden'}
                </ButtonWithBorder>
              </ButtonOuterWrapper>
            </FlexContainer>
          </SectionContainer>
        </form>
      </Space>
    </ContactContainer>
  )
}

interface SectionContainerProps {
  children: ReactElement | ReactElement[] | ReactNode | ReactNode[]
}

const SectionContainer: React.FC<SectionContainerProps> = ({ children }) => {
  return (
    <Wrapper>
      <Space pt={`${AppTheme.spacingSize.L}px`} pb={`${AppTheme.spacingSize.S}px`}>
        {children}
      </Space>
    </Wrapper>
  )
}

const ContactContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  color: ${(props) => props.theme.colors.darkgray};
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const AlertContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10 10 10 10;
`

const ButtonWithBorder = styled.button.attrs({
  type: 'submit'
})<{ disabled: boolean }>`
  height: auto;
  width: auto;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.white};
  appearance: none;
  box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 2px 2px;
  color: ${(props) => (props.disabled ? props.theme.colors.gray : props.theme.colors.secondary)};
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-family: ${(props) => props.theme.fonts.family};
  font-size: ${(props) => props.theme.fonts.sizes.size_Button}px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  &:active {
    box-shadow: none;
  }
`
ButtonWithBorder.displayName = 'Sending_ButtonWithBorder'

const ButtonOuterWrapper = styled.div<{ disabled: boolean }>`
  cursor: pointer;
  display: block;
  border-radius: 5px;
  font-family: ${(props) => props.theme.fonts.family};
  font-size: ${(props) => props.theme.fonts.sizes.size_Button}px;
  text-transform: uppercase;
  padding: 5px;
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.gray : props.theme.colors.secondary};
`

export default Contact
