import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { AppTheme } from '../../../theme'
import { Typography } from '../../atoms'

interface AlertProps {
  onClose: () => void
  title: string
  description: string
}
export const Alert: React.FC<AlertProps> = ({ onClose, title, description }) => {
  return (
    <ThemeProvider theme={AppTheme}>
      <AlertContainer onClick={onClose}>
        <div>
          <Typography fontSize={`${AppTheme.fonts.sizes.size_H2}px`} lineHeight='4em'>
            {title}
          </Typography>
          <Typography fontSize={`${AppTheme.fonts.sizes.size_Paragraph_L}px`} lineHeight='2rem'>
            {description}
          </Typography>
        </div>
      </AlertContainer>
    </ThemeProvider>
  )
}

const AlertContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10 10 10 10;
`

export default Alert
