import React from 'react'
import styled from 'styled-components'

interface CardProps extends CardStyleProps {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[]
}

export const Card: React.FC<CardProps> = ({ children, height, width }) => {
  return (
    <StyledCard height={height} width={width}>
      {children}
    </StyledCard>
  )
}

interface CardStyleProps {
  height?: string
  width?: string
}

const StyledCard = styled.div<CardStyleProps>`
  width: ${(props) => (!!props.width ? props.width : '100%')};
  height: ${(props) => (!!props.height ? props.height : '100%')};
  box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 4px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: ${(props) => props.theme.colors.white};
  overflow-y: scroll;
`

export default Card
