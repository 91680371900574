import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { readableColor } from 'polished'
import { AppTheme, Displays } from '../../../theme'
import { AppStoreContext, getUploadURL } from '../../../utils'
import { Button, FlexContainer, Image, Space, Typography } from '../../atoms'
import { Comparison } from '../../molecules'

interface ResultProps {
  expanded: boolean
  isEmailSent?: boolean
  onShowResult?(): void
  onBackToQuestions?(): void
}
export const Result: React.FC<ResultProps> = observer(
  ({ expanded, isEmailSent, onShowResult, onBackToQuestions }) => {
    const store = useContext(AppStoreContext)
    const [logoURL, setLogoURL] = useState('')
    const [initialClaim, setInitialClaim] = useState('')
    const [resultText, setResultText] = useState('')
    const [contractingAdvantages, setContractingAdvantages] = useState('')
    const [windowInnerWidth, setWindowInnerWidth] = useState(AppTheme.window.innerWidth)
    const fontColor = readableColor(
      AppTheme.colors.secondary,
      AppTheme.colors.stronggray,
      AppTheme.colors.white
    )

    useEffect(() => {
      if (!!store) {
        setLogoURL(getUploadURL + store.ciSettings.settings_sw_file_logo)
        setInitialClaim(store.wtSettings.pricePage.text.textUnderLogo)
        setResultText(store.wtSettings.pricePage.text.show_if_no_price)
        setContractingAdvantages(store.wtSettings.pricePage.text.contractingAdvantages)
      }
      window.addEventListener('resize', () => {
        setWindowInnerWidth(window.innerWidth)
      })
      return () => {
        window.addEventListener('resize', () => {
          setWindowInnerWidth(window.innerWidth)
        })
      }
    }, [getUploadURL, store])

    return (
      <Container>
        <Space pt={`${AppTheme.spacingSize.XS}px`} pb={`${AppTheme.spacingSize.M}px`} height='auto'>
          <ImageContainer>
            <Image src={logoURL} display='block' maxWidth='305px' width='305px' height='100%' />
          </ImageContainer>
        </Space>
        <TextContainer>
          <Space my={`${AppTheme.spacingSize.S}px`}>
            <Typography
              fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
              lineHeight={`${AppTheme.fonts.lineHeights.lineHeight_H2}px`}
              color={AppTheme.colors.darkgray}>
              {initialClaim}
            </Typography>
          </Space>
          <Space my={`${AppTheme.spacingSize.S}px`}>
            <FlexContainer
              className='result_card'
              style={{ padding: '20px', borderRadius: '5px', width: '100%' }}
              bgColor={AppTheme.colors.secondary}
              flexDirection={windowInnerWidth < 1380 ? 'column' : 'row'}
              alignItems={windowInnerWidth < 1380 ? 'center' : 'baseline'}
              justifyContent={
                store!.questionStore.allStandardQuestionAnswered ? 'space-between' : 'flex-start'
              }>
              {!!store && store.questionStore.allStandardQuestionAnswered ? (
                <>
                  {store.questionStore.priceInfo.showPrice ? (
                    <>
                      <Typography
                        className='text_before_price'
                        color={fontColor}
                        textAlignment='center'
                        lineHeight='2rem'
                        width='auto'
                        fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
                        display={Displays.INLINE_BLOCK}>
                        {store.wtSettings.pricePage.text.show_if_price}
                      </Typography>
                      <Typography
                        className='price'
                        color={fontColor}
                        lineHeight='2rem'
                        width='auto'
                        fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
                        display={Displays.INLINE_BLOCK}>
                        {`${store.wtSettings.pricePage.text.show_before_price} ${store.pricePerMonthForPreview}€${store.wtSettings.pricePage.text.show_after_price}`}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        color={fontColor}
                        fontSize={`${AppTheme.fonts.sizes.size_H2}px`}
                        className='alternative_text'>
                        {store.questionStore.priceInfo.alternativeText}
                      </Typography>
                    </>
                  )}
                </>
              ) : (
                <Typography color={fontColor} fontSize={`${AppTheme.fonts.sizes.size_H2}px`}>
                  {resultText}
                </Typography>
              )}
            </FlexContainer>
          </Space>
        </TextContainer>

        {expanded && !isEmailSent && store?.questionStore.showResultTable && (
          <Space my={`${AppTheme.spacingSize.L}px`}>
            <Comparison />
          </Space>
        )}

        {!!store && store.questionStore.allStandardQuestionAnswered && !isEmailSent && (
          <Space mt={`${AppTheme.spacingSize.L}px`} height='auto'>
            <FlexContainer flexDirection={'row'} justifyContent='space-between' alignItems='center'>
              <>
                {!expanded && (
                  <Button
                    visible={!store.questionStore.showAdditionalQuestions}
                    animated
                    hasBorder={false}
                    maxWidth='100px'
                    isUnderlined
                    onClick={!!store && store.questionStore.openAdditionalQuestions}>
                    Genaueres Ergebnis?
                  </Button>
                )}
              </>
              <Button hasBorder onClick={expanded ? onBackToQuestions : onShowResult}>
                {expanded ? 'Zurück' : 'Angebot'}
              </Button>
            </FlexContainer>
          </Space>
        )}
        <TextContainer className='heat_type_hint'>
          <Space my={isEmailSent ? `${AppTheme.spacingSize.XL}px` : `${AppTheme.spacingSize.M}px`}>
            <Typography
              fontSize={`${AppTheme.fonts.sizes.size_Paragraph}px`}
              color={AppTheme.colors.darkgray}>
              {isEmailSent ? contractingAdvantages : store!.questionStore!.heatingTypeText}
            </Typography>
          </Space>
        </TextContainer>
      </Container>
    )
  }
)

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding-bottom: 24px;
`
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TextContainer = styled.div`
  display: block;
  width: 100%;
  height: auto;
  color: ${(props) => props.theme.colors.darkgray};
`

export default Result
