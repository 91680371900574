import Axios from 'axios'
import { getBffURL } from '../utils'

export class API {
  private API_URL = getBffURL
  public fetchCISettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/cisettings')
      // console.log('ci settings from api: ', result.data)
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public fetchQuestions = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/questions')
      // console.log('questions from api: ', result.data)
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public fetchWTSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/wtsettings')
      // console.log('wt settings from api: ', result.data)
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public fetchNotifierSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/notifiersettings')
      // console.log('notifier settings from api: ', result.data)
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public fetchWCEmailSettings = async () => {
    try {
      const result = await Axios.get(this.API_URL + 'api/wcemailsettings')
      // console.log('wc email settings from api: ', result.data)
      return result.data
    } catch (e) {
      console.error(e)
    }
  }

  public calculate = async (data: any) => {
    const options = {
      headers: { 'content-type': 'application/json' }
    }
    try {
      const request = await Axios.post(
        this.API_URL + 'api/heat-tool-calculate',
        JSON.stringify(data),
        options
      )
      // console.log('calculate_______: ', encodeURIComponent(JSON.stringify(data))
      console.log(
        '_____ send calculate request from api: ',
        this.API_URL + 'api/heat-tool-calculate',
        request.data
      )
      return JSON.parse(JSON.stringify(request.data))
    } catch (e) {
      console.error(e)
    }
  }

  public sendEmailRequest = async (data: any) => {
    try {
      const request = await Axios.post(this.API_URL + 'api/save', data)
      console.log('_____ send emial request from api: ', this.API_URL + 'api/save', request)
      return request
    } catch (e) {
      console.error(e)
    }
  }
}

export default API
