import { SelectOptionType } from '../../atoms'

export const AppointmentDays: SelectOptionType[] = [
  { value: 'Montag bis Freitag', checked: false }
  // { value: 'Samstag', checked: false }
]

export const AppointmentDaytime: SelectOptionType[] = [
  { value: 'Morgens', checked: false },
  { value: 'Mittags', checked: false }
  // { value: 'Abends', checked: false }
]

// export const InterestedServices: SelectOptionType[] = [
//   { value: 'Energieberatung', checked: false },
//   { value: 'Öltankentsorgung', checked: false },
//   { value: 'Anlagenprüfung', checked: false },
//   { value: 'Thermographie', checked: false },
//   { value: 'Fördermittelberatung', checked: false },
//   { value: 'Brötchenservice', checked: false }
// ]

type ContactFormFieldType = {
  fieldName:
    | 'customerNumber'
    | 'firstName'
    | 'lastName'
    | 'email'
    | 'tel'
    | 'street'
    | 'zip'
    | 'city'
    | 'comment'
  placeholder: string
  required: boolean
}

export const ContactFormFields: ContactFormFieldType[] = [
  {
    fieldName: 'customerNumber',
    placeholder: 'Kundennummer',
    required: false
  },
  {
    fieldName: 'firstName',
    placeholder: 'Vorname',
    required: true
  },
  {
    fieldName: 'lastName',
    placeholder: 'Nachname',
    required: true
  },
  {
    fieldName: 'email',
    placeholder: 'Email',
    required: true
  },
  {
    fieldName: 'tel',
    placeholder: 'Telefon',
    required: false
  },
  {
    fieldName: 'street',
    placeholder: 'Strasse und Hausnummer',
    required: true
  }
]

export type ContactUserDataType = {
  customer: {
    [key: string]: string | boolean | { [key: string]: string }
    address: { [key: string]: string }
  }
  appointment: { [key: string]: string[] }
  interested: string[]
}
